import { observable, computed, action } from "mobx";

// Stores
import UIStore from "./ui.store";

// API
import { getAllCurrenciesAction } from "../api/currency";

type Currency = {
  id: string;
  shortName: string;
  fullName: string;
  symbol?: string;
};

class CurrenciesStore {
  // Properties
  @observable items: Currency[] = [];

  // Computed

  getCurrencyByName(name): Currency | undefined {
    return this.items.find(
      (item) =>
        item.shortName.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
        item.fullName.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  @action async getAllCurrencies(): Promise<void> {
    try {
      const currencies = await getAllCurrenciesAction();
      if (currencies) this.items = currencies;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new CurrenciesStore();
