import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { FaSortAmountUp } from "react-icons/fa";
import PaginationComponent from "react-reactstrap-pagination";

// Components
import LotItem from "../allLotsItem/allLotsItem";
import Block from "../../components/ui/container/container";

// Types
import { LotItemType } from "../../types/lots";

// Styles
import styles from "./allLotsList.module.sass";

type AllLotsLitsComponentProps = {
  items: LotItemType[];
  approve: Function;
  reject: Function;
  closeLot: Function;
  currentUser: any;
  calculateInvestments: Function;
  locale: any;
};

const AllLotsListComponent: React.FC<AllLotsLitsComponentProps> = ({
  items,
  approve,
  reject,
  closeLot,
  currentUser,
  calculateInvestments,
  locale,
}: AllLotsLitsComponentProps) => {
  const pageSize = 5;

  const lots = useMemo(()=>(
    items.filter(
      (lot) =>
        ["ACTIVE", "EXECUTION"].includes(lot.status?.name) ||
        lot.owner.id === currentUser.id ||
        currentUser.role.name === "admin"
    )
  ), [items]);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const pageItems = useMemo(() => {
    const startPageIndex = (currentPage-1) * pageSize
    return lots.slice(startPageIndex, startPageIndex + pageSize );
  }, [currentPage, lots])

  return (
    <div className={styles.AllLotsList}>
      {pageItems.map((lot: LotItemType) => (
        <div key={lot.id}>
          <Block>
            <Row>
              <Col>
                <LotItem
                  lot={lot}
                  approve={approve}
                  reject={reject}
                  closeLot={closeLot}
                  currentUser={currentUser}
                  calculateInvestments={calculateInvestments}
                  locale={locale}
                />
              </Col>
            </Row>
          </Block>
        </div>
      ))}
      {!!lots.length && (<div className={styles.pagesContainer}>
        <PaginationComponent
          totalItems={lots.length}
          pageSize={pageSize}
          onSelect={setCurrentPage}
          firstPageText='<<'
          previousPageText='<'
          nextPageText='>'
          lastPageText='>>'
        />
      </div>)}
      {!lots.length && <p>{ locale.validatorMessages.noLots }</p>}
    </div>
  );
};

export default AllLotsListComponent;
