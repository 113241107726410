import { GraphQLAPIURL } from "../config/config";
import axios from "axios";
import escapeQuotes from "../helpers/escapeQuote";
import { AwesomeGraphQLClient } from "awesome-graphql-client";

const client = new AwesomeGraphQLClient({ endpoint: GraphQLAPIURL });

export async function getAllOrdersAction() {
  const query = `#graphql
  query {
    allOrders {
      id,
      value,
      currency {
          id,
          shortName
      },
      status,
      type,
      requisites,
      owner {
          id,
          email
      },
      lot {
        id,
        shortName
      },
      stage {
        id,
        title,
        startDate,
        stageDescription,
        cost,
        funded,
        completed,
        lot {
          id,
          shortName,
          shortDescription
        }
      }
    }
  }
  `;
  const response = await axios
    .post(GraphQLAPIURL, {
      query,
    })
    .then((response) => response.data);
  if (response.errors) return response;
  return response.data.allOrders;
}

type OrderWhereInput = {
  id?: string;
};

export async function getOrderAction({ id }) {
  const variables: OrderWhereInput = {
    id,
  };
  const request = `#graphql
    query($id: ID!) {
        Order(where: {id: $id}) {
            id,
            value,
            currency {
                id,
                shortName
            },
            status,
            type,
            requisites,
            owner {
                id,
                email
            },
            lot {
              id,
              shortName
            },
            stage {
              id,
              title,
              startDate,
              stageDescription,
              cost,
              funded,
              completed,
              index,
              status,
              lot {
                id,
                shortName,
                shortDescription
              }
            }
        }
    }
  `;
  const response: any = await client.request(request, variables);

  return response.Order;
}

export async function createOrderAction({
  owner = null,
  currency = null,
  value = 0,
  type = "topup",
  status = "ACTIVE",
  requisites = ""
}) {
  const variables = {
    data: {
      value: +value,
      currency: {
        connect: { id: currency },
      },
      owner: {
        connect: { id: owner },
      },
      type,
      status,
      requisites
    },
  };
  const request = `#graphql
  mutation(
    $data: OrderCreateInput
  ) {
    createOrder(data: $data) {
      id,
      value,
      currency {
          id,
          shortName
      },
      status,
      type,
      requisites,
      owner {
          id,
          email
      },
      lot {
        id,
        shortName
      },
      stage {
        id,
        title,
        startDate,
        stageDescription,
        cost,
        funded,
        completed,
        index,
        lot {
          id,
          shortName,
          shortDescription
        }
      }
    }
  }
  `;
  const response: any = await client.request(request, variables);

  return response.createOrder;
}

export async function updateOrderAction({
  id,
  value = 0,
  type = "topup",
  status = "ACTIVE",
}) {
  const variables = {
    id,
    data: {
      value: +value,
      type,
      status,
    },
  };
  const request = `#graphql
    mutation(
      $id: ID!,
      $data: OrderUpdateInput
    ) {
      updateOrder(id: $id, data: $data) {
        id,
        value,
        currency {
            id,
            shortName
        },
        status,
        type,
        owner {
            id,
            email
        },
        lot {
          id,
          shortName
        },
        stage {
          id,
          title,
          startDate,
          stageDescription,
          cost,
          funded,
          completed,
          index,
          status,
          lot {
            id,
            shortName,
            shortDescription
          }
        }
      }
    }
    `;
  const response: any = await client.request(request, variables);

  return response.updateOrder;
}
