import React, { useEffect } from "react";
import {
    Card,
    CardTitle,
    CardBody,
    CardText,
    Table,
    Row,
    Col,
  } from "reactstrap";
  import { Link } from "react-router-dom";
  import moment from "moment";
  import Block from "../../../../components/ui/container/container";

const LotCardItem = ({item, locale, styles}) => {
    return (

            <div className={styles.ivestmentsLotSliderItem}>
            <Block>
                <Link to={`/lots/${item.id}`}>
                <h5>{item.shortName}</h5>
                </Link>
                <hr />
                <Table condensed>
                <tbody>
                    <tr>
                    <td>{locale.lotDetails.startOfCollection}</td>
                    <td>
                        {moment(item.startOfCollection).format(
                        "DD.MM.YYYY"
                        )}
                    </td>
                    </tr>
                    <tr>
                    <td>{locale.lotDetails.endOfCollection}</td>
                    <td>
                        {moment(item.endOfCollection).format(
                        "DD.MM.YYYY"
                        )}
                    </td>
                    </tr>
                    <tr>
                    <td>{locale.lotDetails.startOfExecution}</td>
                    <td>
                        {moment(item.startOfExecution).format(
                        "DD.MM.YYYY"
                        )}
                    </td>
                    </tr>
                    <tr>
                    <td>{locale.lotDetails.endOfExecution}</td>
                    <td>
                        {moment(item.endOfExecution).format(
                        "DD.MM.YYYY"
                        )}
                    </td>
                    </tr>
                    <tr>
                    <td>{locale.lotDetails.returnInvestmentsDate}</td>
                    <td>
                        {moment(item.returnInvestmentsDate).format(
                        "DD.MM.YYYY"
                        )}
                    </td>
                    </tr>
                    <tr>
                    <td>{locale.lotDetails.profit}</td>
                    <td>{item.profit}%</td>
                    </tr>
                </tbody>
                </Table>
            </Block>
            </div>

    );
}

export default LotCardItem;