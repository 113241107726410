import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import {
  Card,
  CardTitle,
  CardBody,
  CardText,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap";
import moment from "moment";
import Modal from "../../components/ui/modal/modal";
import { FormGroup, Label, Input } from "reactstrap";

// Components
import Block from "../../components/ui/container/container";
import MoneyInput from "../../components/ui/moneyInput/moneyInput";

// Styles
import styles from "./wallet.module.sass";

// Services
import MetaMask from "../../services/metamask.service";

const WalletPage: React.FC = inject(
  "UserStore",
  "LotsStore",
  "InvestmentStore",
  "ModalsStore",
  "CurrenciesStore",
  "TransactionsStore",
  "LocalesStore"
)(
  observer(
    ({
      UserStore,
      InvestmentStore,
      ModalsStore,
      CurrenciesStore,
      TransactionsStore,
      LocalesStore,
    }: any) => {
      useEffect(() => {
        const userId = UserStore.currentUser.id;
        InvestmentStore.getInvestments({ userId });
        TransactionsStore.getTransactions({ userId });
      }, []);
      const { topUpModal, withdrawModal } = ModalsStore;
      const { locale } = LocalesStore;
      const transactions = TransactionsStore.items;

      return (
        <div className={styles.wallet}>
          <Modal
            title={locale.wallet.modals.topup.header}
            hide={topUpModal.hide.bind(topUpModal)}
            visible={topUpModal.visible}
            submitLabel="Создать заявку"
            submit={topUpModal.submit.bind(topUpModal, {
              userId: UserStore.currentUser.id,
            })}
          >
            <FormGroup>
              <Label for="summ">{locale.wallet.modals.topup.summLabel}</Label>
              <MoneyInput
                name="summ"
                value={topUpModal.summ}
                onChange={(value) => topUpModal.change(value, "summ")}
                currencyUnit={'$'}
                placeholder={locale.wallet.modals.topup.summPlaceholder}
              />
            </FormGroup>
            <FormGroup>
              <Label for="summ">
                {locale.wallet.modals.topup.currencyLabel}
              </Label>
              <Input
                type="select"
                name="currency"
                value={topUpModal.currency}
                onChange={(e) => topUpModal.change(e.target.value, "currency")}
                placeholder={locale.wallet.modals.topup.currenyPlaceholder}
                disabled
              >
                {CurrenciesStore.items.map((currency) => (
                  <option value={currency.id}>{currency.shortName}</option>
                ))}
              </Input>
            </FormGroup>
          </Modal>
          <Modal
            title={locale.wallet.modals.withdraw.header}
            hide={withdrawModal.hide.bind(withdrawModal)}
            visible={withdrawModal.visible}
            submitLabel="Создать заявку"
            submit={withdrawModal.submit.bind(withdrawModal, {
              userId: UserStore.currentUser.id,
            })}
          >
            <FormGroup>
              <Label for="summ">
                {locale.wallet.modals.withdraw.summLabel}
              </Label>
              <MoneyInput
                name="summ"
                currencyUnit={'$'}
                value={withdrawModal.summ}
                onChange={(value) => withdrawModal.change(value, "summ")}
                placeholder={locale.wallet.modals.withdraw.summPlaceholder}
              />
            </FormGroup>
            <FormGroup>
              <Label for="requisites">
                {locale.wallet.modals.withdraw.requisitesLabel}
              </Label>
              <Input
                type="textarea"
                name="requisites"
                value={withdrawModal.requisites}
                onChange={(e) =>
                  withdrawModal.change(e.target.value, "requisites")
                }
                placeholder={
                  locale.wallet.modals.withdraw.requisitesPlaceholder
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="summ">
                {locale.wallet.modals.withdraw.currencyLabel}
              </Label>
              <Input
                type="select"
                name="currency"
                value={withdrawModal.currency}
                onChange={(e) =>
                  withdrawModal.change(e.target.value, "currency")
                }
                placeholder={locale.wallet.modals.withdraw.currenyPlaceholder}
                disabled
              >
                {CurrenciesStore.items.map((currency) => (
                  <option value={currency.id}>{currency.shortName}</option>
                ))}
              </Input>
            </FormGroup>
          </Modal>
          <Row>
            <Col md={6}>
              <Block>
                <h2>{locale.wallet.address.header}</h2>
                <pre>{UserStore.currentUser.ethAddress || "-"}</pre>
              </Block>
              <Block>
                <h2>{locale.wallet.topup.header}</h2>
                <Row>
                  <Col>
                    <Button
                      color="primary"
                      block
                      onClick={topUpModal.show.bind(topUpModal)}
                      disabled={!MetaMask.pluginInstalled()}
                    >
                      {locale.buttons.topupWithBank}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      color="primary"
                      block
                      onClick={withdrawModal.show.bind(withdrawModal)}
                      disabled={!MetaMask.pluginInstalled()}
                    >
                      {locale.buttons.withdrawWithBank}
                    </Button>
                  </Col>
                </Row>
                {!MetaMask.pluginInstalled() && (
                  <Row>
                    <Col>
                      <p className="error">
                        {locale.announcements.metamaskNotInstalled}
                      </p>
                    </Col>
                  </Row>
                )}
              </Block>
            </Col>
            <Col md={6}>
              <Block>
                <h2>{locale.wallet.balance.header}</h2>
                <Table striped>
                  <thead>
                    <tr>
                      <th>{locale.wallet.balance.name}</th>
                      <th>{locale.wallet.balance.amount}</th>
                      <th>{locale.wallet.balance.cost}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {UserStore.currentUser.balances.map((item) => (
                      <tr key={item.id}>
                        <td>{item.currency.shortName}</td>
                        <td>{item.value.toFixed(2)}</td>
                        <td>-</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Block>
            </Col>
          </Row>
          <Row>
            <Col>
              <Block>
                <h2>{locale.wallet.transactionsHistory.header}</h2>
                {!transactions.length && (
                  <p>{locale.validatorMessages.noTransactions}</p>
                )}
                <div className="mobileOverflowX">
                  <Table striped>
                    <thead>
                      <tr>
                        <th>{locale.wallet.transactionsHistory.date}</th>
                        <th>
                          {locale.wallet.transactionsHistory.transactionLink}
                        </th>
                        <th>
                          {locale.wallet.transactionsHistory.type}
                        </th>
                        <th>{locale.wallet.transactionsHistory.from}</th>
                        <th>{locale.wallet.transactionsHistory.to}</th>
                        <th>{locale.wallet.transactionsHistory.value}</th>
                        <th>{locale.wallet.transactionsHistory.txId}</th>

                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((item) => (
                        <tr>
                          <td>{moment(item.date).format("DD.MM.YYYY")}</td>
                          <td>
                            <a href={`https://sepolia.etherscan.io/tx/${item.txId}`} target="_blank">
                              {locale.wallet.transactionsHistory.linkText}
                            </a>
                          </td>
                          <td>{ locale.wallet.transactionTypes[item.type] }</td>
                          <td>{item.from || '-'}</td>
                          <td>{item.to || '-'}</td>
                          <td>{item.value / 100}</td>
                          <td>{item.txId}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Block>
            </Col>
          </Row>
        </div>
      );
    }
  )
);

export default WalletPage;
