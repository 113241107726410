import { observable, computed, action } from "mobx";
import { resolve } from "dns";

// API
import {
  getAllLotsAction,
  getLotByIdAction,
  createLotAction,
  updateLotAction,
  setLotStatusAction
} from "../api/lot";

// Stores
import UIStore from "./ui.store";
import UserStore from "./user.store";
import LocalesStore from "./locales.store";
import LotStatusesStore from "./lotStatuses.store";

// Types
import { LotItemType } from "../types/lots";
class LotsStore {
  // Properties
  @observable items: LotItemType[] = [];
  @observable item: LotItemType | null = null;

  // Computed

  // Actions
  @action async getAllLots(): Promise<any[]> {
    this.items = await getAllLotsAction();
    return this.items;
  }

  @action async getLotById(lotId: string): Promise<any> {
    this.item = await getLotByIdAction(lotId);
    return this.item;
  }

  @action cleanItem(): void {
    this.item = null;
  }

  @action async createNewLot(lot): Promise<LotItemType | null> {
    const currentLocale = window.localStorage.getItem("wctUserLocale") || "en_US";
    const newLot: LotItemType = await createLotAction({
      owner: UserStore.currentUser?.id,
      ...lot,
    });
    if (newLot) {
      this.items.push(newLot);
      UIStore.createFlash({
        type: "success",
        content: LocalesStore.locales[currentLocale].data.flashMessages.lotCreated,
      });
      return newLot;
    }
    UIStore.createFlash({
      type: "danger",
      content: LocalesStore.locales[currentLocale].data.flashMessages.lotCreationError,
    });
    return null;
  }

  @action async setLotStatus({ lotId, status }): Promise<LotItemType | null> {
    const currentLocale = window.localStorage.getItem("wctUserLocale") || "en_US";
    const statusId = LotStatusesStore.items.find(item => item.name === status)?.id;
    const updatedLot: LotItemType = await setLotStatusAction({
      lotId,
      statusId,
    });

    if (updatedLot) {
      this.items = this.items.map(lot => {
        if(lot.id === updatedLot.id) return updatedLot;
        return lot;
      })
      UIStore.createFlash({
        type: "success",
        content: LocalesStore.locales[currentLocale].data.flashMessages.lotUpdated,
      });
      return updatedLot;
    }
    UIStore.createFlash({
      type: "danger",
      content: LocalesStore.locales[currentLocale].data.flashMessages.lotUpdateError,
    });
    return null;
  }

  @action async updateLot(lot): Promise<LotItemType | null> {
    const currentLocale = window.localStorage.getItem("wctUserLocale") || "en_US";

    const updatedLot: LotItemType = await updateLotAction(lot);

    if (updatedLot) {
      this.items = this.items.map(lot => {
        if(lot.id === updatedLot.id) return updatedLot;
        return lot;
      })
      UIStore.createFlash({
        type: "success",
        content: LocalesStore.locales[currentLocale].data.flashMessages.lotUpdated,
      });
      return updatedLot;
    }
    UIStore.createFlash({
      type: "danger",
      content: LocalesStore.locales[currentLocale].data.flashMessages.lotUpdateError,
    });
    return null;
  }

  @action async updateLotStage(lot, stage): Promise<LotItemType | null> {
    const updatedLot: LotItemType = await updateLotAction({
      ...lot,
      stages: lot.stages.map(item => {
        if(item.id === stage.id) return stage;
        return item
      })
    });

    if (updatedLot) {
      this.items = this.items.map(lot => {
        if(lot.id === updatedLot.id) return updatedLot;
        return lot;
      });
      this.item = {...updatedLot};
      return updatedLot;
    }
    return null;
  }

  calculateInvestments(lot) {
    const investments = lot.investments || [];
    const investmentsSumm = investments.reduce((accumulator, investment) => {
      return accumulator + investment.value;
    }, 0);
    return investmentsSumm;
  }
}

export default new LotsStore();
