import React, { useEffect } from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { FaSortAmountUp } from "react-icons/fa";

// Components
import Block from "../ui/container/container";

// Styles
import styles from "./allLotsRightFilter.module.sass";

const AllLotsRightFilterComponent: React.FC<any> = ({ locale }: any) => (
  <div className={styles.AllLotsRightFilterComponent}>
    <Row>
      <Col>
        <Block>
            <h4>{ locale.lotsFilter.tradingHouses }</h4>
            {/* <Form>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" />{' '}
                        "Выкуп билетов"
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" />{' '}
                        "Платежные авиалинии"
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" />{' '}
                        "Стройдом"
                    </Label>
                </FormGroup>
            </Form> */}
        </Block>
        <Block>
            <h4>{ locale.lotsFilter.lotStatuses }</h4>
            <Form>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" />{' '}
                        { locale.statuses.MODERATION }
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" />{' '}
                        { locale.statuses.ACTIVE }
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" />{' '}
                        { locale.statuses.EXECUTION }
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" />{' '}
                        { locale.statuses.COMPLETED }
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" />{' '}
                        { locale.statuses.EXPIRED }
                    </Label>
                </FormGroup>
            </Form>
        </Block>
      </Col>
    </Row>
  </div>
);

export default AllLotsRightFilterComponent;
