import { GraphQLAPIURL } from "../config/config";
import axios from "axios";
import escapeQuotes from "../helpers/escapeQuote";
import { AwesomeGraphQLClient } from "awesome-graphql-client";

const client = new AwesomeGraphQLClient({ endpoint: GraphQLAPIURL });

export async function getAllLotsAction() {
  const query = `#graphql
    {
      allLots {
        id,
        shortName,
        fullName,
        summaryValue,
        minPaidSumm,
        profit,
        shortDescription,
        fullDescription,
        status {
          name
        },
        stages {
          stageDescription,
          startDate,
          funded,
          cost,
          index,
          status
        },
        participiants {
          name
        }
        files {
          id,
          file {
            id,
            filename,
            originalFilename,
            publicUrl
          }
        },
        investments {
          value,
          currency {
            symbol
          }
        },
        startOfCollection,
        endOfCollection,
        startOfExecution,
        endOfExecution,
        returnInvestmentsDate,
        owner {
          id,
          email
        }
      }
    }
    `;
  const response = await axios
    .post(GraphQLAPIURL, {
      query,
    })
    .then((response) => response.data);
  return response.data.allLots;
}

export async function getLotByIdAction(lotId: string) {
  const query = `#graphql
    {
      Lot(where: {id: "${lotId}"}) {
        id,
        shortName,
        fullName,
        summaryValue,
        minPaidSumm,
        profit,
        shortDescription,
        fullDescription,
        status {
          name
        },
        owner {
          email
        },
        stages {
          id,
          title,
          stageDescription,
          startDate,
          completed,
          funded,
          cost,
          index,
          status
        },
        participiants {
          id,
          name
        },
        files {
          id,
          file {
            id,
            filename,
            originalFilename,
            publicUrl
          }
        },
        investments {
          id,
          value
        },
        startOfCollection,
        endOfCollection,
        startOfExecution,
        endOfExecution,
        returnInvestmentsDate
      }
    }
    `;
  const response = await axios
    .post(GraphQLAPIURL, {
      query,
    })
    .then((response) => response.data);
  return response.data.Lot;
}

export async function createLotAction({
  shortName,
  fullName,
  summaryValue,
  minPaidSumm,
  profit,
  shortDescription,
  fullDescription,
  stages,
  participiants,
  files,
  startOfCollection,
  endOfCollection,
  startOfExecution,
  endOfExecution,
  returnInvestmentsDate,
  owner,
}) {
  let stagesQuery = stages
    .map((stage) => {
      return `{
      title: "${escapeQuotes(stage.title)}",
      stageDescription: "${escapeQuotes(stage.stageDescription)}",
      startDate: "${stage.startDate}",
      completed: false,
      funded: false,
      index: ${stage.index},
      cost: ${stage.cost}
    }`;
    })
    .join(",");

  let participiantsQuery = participiants
    .map((participiant) => {
      return `{
      name: "${escapeQuotes(participiant.name)}"
    }`;
    })
    .join(",");

  let filesQuery = files
    .map((item) => {
      return `{
      id: "${item.id}",
    }`;
    })
    .join(",");

  const query = `#graphql
  mutation {
    createLot(data: {
      shortName: "${shortName}",
      fullName: "${fullName}",
      summaryValue: ${+summaryValue},
      minPaidSumm: ${+minPaidSumm},
      profit: ${profit},
      shortDescription: "${escapeQuotes(shortDescription)}",
      fullDescription: "${escapeQuotes(fullDescription)}",
      stages: {
        create: [${stagesQuery}]
      },
      participiants: {
        create: [${participiantsQuery}]
      },
      files: {
        connect:[${filesQuery}]
      },
      owner: {
        connect: {
          id: "${owner}"
        }
      }
      startOfCollection: "${startOfCollection}",
      endOfCollection: "${endOfCollection}",
      startOfExecution: "${startOfExecution}",
      endOfExecution: "${endOfExecution}",
      returnInvestmentsDate: "${returnInvestmentsDate}"
    }) {
      id,
        shortName,
        fullName,
        summaryValue,
        minPaidSumm,
        profit,
        shortDescription,
        fullDescription,
        status {
          name
        },
        owner {
          email
        },
        stages {
          id,
          index,
          title,
          stageDescription,
          startDate,
          completed
        },
        participiants {
          id,
          name
        },
        files {
          id,
          file {
            id,
            filename,
            originalFilename,
            publicUrl
          }
        },
        investments {
          id,
          value
        },
        startOfCollection,
        endOfCollection,
        startOfExecution,
        endOfExecution,
        returnInvestmentsDate
    }
  }
  `;

  const response = await axios
    .post(GraphQLAPIURL, {
      query,
    })
    .then((response) => response.data);
  return response.data.createLot;
}

export async function updateLotAction({
  id,
  shortName,
  fullName,
  summaryValue,
  minPaidSumm,
  profit,
  shortDescription,
  fullDescription,
  stages,
  participiants,
  files,
  startOfCollection,
  endOfCollection,
  startOfExecution,
  endOfExecution,
  returnInvestmentsDate,
}) {
  const request = `#graphql
    mutation (
      $id: ID!,
      $shortName: String,
      $fullName: String,
      $summaryValue: Float,
      $minPaidSumm: Float,
      $profit: Float,
      $shortDescription: String,
      $fullDescription: String,
      $stages: [StagesUpdateInput],
      $participiants: [LotParticipiantCreateInput]
      $files: [UploadedFileWhereUniqueInput]
      $startOfCollection: String,
      $endOfCollection: String,
      $startOfExecution: String,
      $endOfExecution: String,
      $returnInvestmentsDate: String
    ){
      updateStages(data: $stages) {
        id,
        title,
        stageDescription,
        startDate,
        completed,
        cost,
        funded,
        index,
        status
      }
      updateLot(id: $id, data: {
        shortName: $shortName,
        fullName: $fullName,
        summaryValue: $summaryValue,
        minPaidSumm: $minPaidSumm,
        profit: $profit,
        shortDescription: $shortDescription,
        fullDescription: $fullDescription,
        participiants: {
          disconnectAll: true,
          create: $participiants
        },
        files: {
          disconnectAll: true,
          connect: $files
        },
        startOfCollection: $startOfCollection,
        endOfCollection: $endOfCollection,
        startOfExecution: $startOfExecution,
        endOfExecution: $endOfExecution,
        returnInvestmentsDate: $returnInvestmentsDate
      }) {
        id,
        shortName,
        fullName,
        summaryValue,
        minPaidSumm,
        profit,
        shortDescription,
        fullDescription,
        status {
          id,
          name
        },
        owner {
          email
        },
        participiants {
          id,
          name
        },
        files {
          id,
          file {
            id,
            filename,
            originalFilename,
            publicUrl
          }
        },
        investments {
          id,
          value
        },
        startOfCollection,
        endOfCollection,
        startOfExecution,
        endOfExecution,
        returnInvestmentsDate
      }
    }
    `;
  const response: any = await client.request(request, {
    id,
    shortName,
    fullName,
    summaryValue,
    minPaidSumm,
    profit,
    shortDescription,
    fullDescription,
    participiantsIDs: participiants.map((participiant) => participiant.id),
    stages: stages.map(
      ({
        id,
        index,
        status,
        title,
        stageDescription,
        startDate,
        cost,
        funded,
      }) => {
        return {
          id,
          data: {
            index,
            title,
            stageDescription,
            startDate,
            cost,
            funded,
            status,
          },
        };
      }
    ),
    startOfCollection,
    endOfCollection,
    startOfExecution,
    endOfExecution,
    returnInvestmentsDate,
  });

  return {
    ...response.updateLot,
    stages: response.updateStages,
  };
}

export async function setLotStatusAction({ lotId, statusId }) {
  const request = `#graphql
    mutation (
      $lotId: ID!,
      $statusId: ID!
    ){
      updateLot(id: $lotId, data: {
        status: {
          disconnectAll: true,
          connect: {
            id: $statusId
          }
        }
      }) {
        id,
        shortName,
        fullName,
        summaryValue,
        minPaidSumm,
        profit,
        shortDescription,
        fullDescription,
        status {
          id,
          name
        },
        stages {
          id,
          stageDescription,
          startDate,
          completed,
          funded,
          status,
          index
        },
        participiants {
          id,
          name
        },
        files {
          id,
          file {
            originalFilename,
            publicUrl
          }
        },
        startOfCollection,
        endOfCollection,
        startOfExecution,
        endOfExecution,
        returnInvestmentsDate
      }
    }
    `;

  const response: any = await client.request(request, {
    lotId,
    statusId,
  });

  return response.updateLot;
}
