import React from "react";
import NumberFormat from "react-number-format";
import { Progress } from "reactstrap";

// Styles
import styles from "./paymentProgress.module.sass";

type PaymentProgressProps = {
  value: number;
  remaining: number;
  lotStatus: string;
  locale: any;
};

const PaymentProgress: React.FC<PaymentProgressProps> = ({
  value,
  remaining,
  lotStatus,
  locale
}: PaymentProgressProps) => (
  <div className={styles.process}>
    <span>{ locale.lotDetails.fundingProgress }</span>
    { ["DRAFT", "MODERATION"].includes(lotStatus) && <span className="text-right">{ locale.lotDetails.lotPrepating }</span> }
    { lotStatus === "ACTIVE" && <span className={styles.remaining}>
      { locale.lotDetails.remaining }{" "}
      <NumberFormat
        value={remaining}
        displayType={"text"}
        thousandSeparator={true}
      />{" "}
      { locale.currencies.USD }
    </span> }
    { lotStatus === "EXECUTION" && <span className="text-right">{ locale.lotDetails.fundsCollected }</span>}
    
    <Progress multi>
      <Progress striped bar value={value} />
    </Progress>
  </div>
);

export default PaymentProgress;
