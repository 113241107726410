import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";

// Components
import { Row, Col, Button } from "reactstrap";
import Block from "../../components/ui/container/container";
import TopFilter from "../../components/allLotsTopFilter/allLotsTopFilter";
import RightFilter from "../../components/allLotsRightFilter/allLotsRightFilter";
import AllLotsList from "../../components/allLotsList/allLotsList";

// Styles
import styles from "./allLots.module.sass";

const AllLotsPage: React.FC = inject(
  "LotsStore",
  "UserStore",
  "LocalesStore"
)(
  observer(({ LotsStore, UserStore, LocalesStore }: any) => {
    useEffect(() => {
      LotsStore.getAllLots();
    }, []);
    const approveLotHandler = (lot) => {
      LotsStore.setLotStatus({
        lotId: lot.id,
        status: "ACTIVE",
      });
    };
    const rejectLotHandler = (lot) => {
      LotsStore.setLotStatus({
        lotId: lot.id,
        status: "REJECTED",
      });
    };
    const closeLotHandler = (lot) => {
      LotsStore.setLotStatus({
        lotId: lot.id,
        status: "COMPLETED",
      });
    };
    const { locale } = LocalesStore;
    return (
      <div>
        <Block>
          <TopFilter locale={locale} />
        </Block>
        <Row className={styles.allLotsContentContainer}>
          <Col md={9}>
            {["admin", "operator", "tradinghouse"].includes(UserStore.currentUser.role.name) && (
              <Link to="/lots/new">
                <Button color="primary">{locale.buttons.createLot}</Button>
              </Link>
            )}

            <hr />
            <AllLotsList
              items={LotsStore.items}
              approve={approveLotHandler}
              reject={rejectLotHandler}
              closeLot={closeLotHandler}
              currentUser={UserStore.currentUser}
              calculateInvestments={LotsStore.calculateInvestments}
              locale={LocalesStore.locale}
            />
          </Col>
          <Col>
            <RightFilter locale={locale} />
          </Col>
        </Row>
      </div>
    );
  })
);

export default AllLotsPage;
