import React, { useEffect } from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { FaSortAmountUp } from "react-icons/fa";

// Styles
import styles from "./allLotsTopFilter.module.sass";

const AllLotsTopFilterComponent: React.FC<any> = ({ locale }: any) => (
  <div className={styles.AllLotsTopFilterComponent}>
    <Row>
      <Col className={styles.sortByDate} sm={12} md={2} >
        <span>{ locale.lotsFilter.byDateLabel }</span>
        <FaSortAmountUp className={`${styles.icon} icon`} />
      </Col>
      <Col className={styles.minSumm} sm={12} md={5}>
        <Form inline className={styles.minSummForm}>
          <FormGroup className={styles.formGroup}>
            <Label>{ locale.lotsFilter.minimumContribution }</Label>
          </FormGroup>
          <FormGroup className={styles.formGroup}>
            <Input type="text" name="minSummFrom" placeholder={ locale.lotsFilter.minimumContributionFrom } />
          </FormGroup>
          <FormGroup className={styles.formGroup}>
            <Input type="text" name="minSummTo" placeholder={ locale.lotsFilter.minimumContributionTo } />
          </FormGroup>
        </Form>
      </Col>
      <Col className={styles.summaryValue} sm={12} md={5}>
        <Form inline className={styles.summaryValue}>
          <FormGroup className={styles.formGroup}>
            <Label>{ locale.lotsFilter.summaryValue }</Label>
          </FormGroup>
          <FormGroup className={styles.formGroup}>
            <Input type="text" name="summaryValueFrom" placeholder={ locale.lotsFilter.summaryValueFrom } />
          </FormGroup>
          <FormGroup className={styles.formGroup}>
            <Input type="text" name="summaryValueTo" placeholder={ locale.lotsFilter.summaryValueTo } />
          </FormGroup>
        </Form>
      </Col>
    </Row>
  </div>
);

export default AllLotsTopFilterComponent;
