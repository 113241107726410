import React, {useRef, useCallback} from "react";
import { Input } from "reactstrap";
import NumberFormat from "react-number-format";
import styles from "./container.module.sass";

type MoneyInputProps = {
	name: string;
	currencyUnit: string;
	value?: string;
	onChange: Function;
	placeholder?: string;
	className?: string;
};

const MoneyInput: React.FC<MoneyInputProps> = (props: MoneyInputProps) => {
	const {name, value, onChange, placeholder, currencyUnit, className} = props;
	return (
		<NumberFormat
			name={name}
			thousandSeparator={true}
			prefix={currencyUnit}
			value={value}
			onValueChange={({formattedValue, value}) => onChange(value)}
			placeholder={placeholder}
			className={`form-control ${className}`}
		/>
	);
}
export default MoneyInput;
