import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";

// Components
import { Row, Col, Button } from "reactstrap";
import Block from "../../components/ui/container/container";

// Styles

const AllOrdersPage: React.FC = inject(
  "UserStore",
  "OrdersStore",
  "UIStore",
  "LocalesStore"
)(
  observer(({ UserStore, OrdersStore, UIStore, LocalesStore }: any) => {
    useEffect(() => {
      OrdersStore.getAllOrders();
    }, []);
    const changeOrderStatusHandler = async (order, status) => {
      await OrdersStore.updateOrder({
        ...order,
        status,
      });
      UIStore.createFlash({
        type: "success",
        content: locale.flashMessages.orderUpdated,
      });
    };
    const { locale } = LocalesStore;
    return (
      <Row>
        {OrdersStore.items
          .filter((order) => order.status !== "ARCHIVE")
          .map((order) => (
            <Col md={4}>
              <Block>
                <h4>
                  <Link to={`/orders/${order.id}`}>{ locale.orderDetails.header} #{order.id}</Link>
                </h4>
                <hr />
                <p>
                  <strong>{ locale.orderDetails.initiator }:</strong> {order.owner.email}
                  <br />
                  <strong>{ locale.orderDetails.type }:</strong>{" "}
                  <span>{ locale.orderTypes[order.type] } { order.stage && <span>({ order.stage.title })</span> }</span>
                  <br />
                  <strong>{ locale.orderDetails.status }:</strong> { locale.orderStatuses[order.status] }
                  <br />
                  <strong>{ locale.orderDetails.summ }:</strong> {order.value} {order.currency.shortName}
                  { order.stage && <span>
                    <br />
                    <strong>{ locale.orderDetails.lot }:</strong> <Link to={`/lots/${order.stage.lot.id}`}>{order.stage.lot.shortName}</Link>
                  </span> }
                </p>
                <hr />
                {order.status !== "COMPLETED" && (
                  <Button
                    color="success"
                    onClick={() => changeOrderStatusHandler(order, "COMPLETED")}
                  >
                    { locale.buttons.confirmOrder }
                  </Button>
                )}{" "}
                {order.status !== "REJECTED" && order.status !== "COMPLETED" && (
                  <Button
                    color="danger"
                    onClick={() => changeOrderStatusHandler(order, "REJECTED")}
                  >
                    { locale.buttons.rejectOrder }
                  </Button>
                )}{" "}
                <Button
                  color="secondary"
                  onClick={() => changeOrderStatusHandler(order, "ARCHIVE")}
                >
                  { locale.buttons.archiveOrder }
                </Button>
              </Block>
            </Col>
          ))}
      </Row>
    );
  })
);

export default AllOrdersPage;
