import React, { Component, useEffect } from "react";
import { Route, Switch } from "react-router";
import { inject, observer } from "mobx-react";

// pages
import AllLots from "./allLots";
import NewLot from "./newLot/newLot";
import LotDetails from "./lotDetails/lotDetails";
import EditLot from "./editLot/editLot";

const allLots: React.FC = ({ routing, NavigationStore, UserStore }: any) => {
    return (
         <div>
             <Switch>
                <Route path={`/lots`} component={AllLots} exact />
                <Route path={`/lots/new`} component={NewLot} exact />
                <Route path={`/lots/:lotId`} component={LotDetails} exact />
                <Route path={`/lots/:lotId/edit`} component={EditLot} exact />
             </Switch>
         </div>
    );
  }

export default allLots;
