import React from "react";

// Styles
import styles from "./announcement.module.sass";

type AnnouncmentComponentProps = {
  type: string;
  children: any;
};

const AnnouncmentComponent: React.FC<AnnouncmentComponentProps> = ({
  type = "info",
  children,
}: AnnouncmentComponentProps) => {
  return (
    <div className={`${styles.announcementItem} ${styles.danger}`}>
      {children}
    </div>
  );
};

export default AnnouncmentComponent;
