import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from "history";
import { Provider } from "mobx-react";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";
import { Router } from "react-router";
import axios from "axios";

// stores
import LocalesStore from "./stores/locales.store";
import NavigationStore from "./stores/navigation.store";
import UserStore from "./stores/user.store";
import FormsStore from "./stores/forms.store";
import LotsStore from "./stores/lots.store";
import InvestmentStore from "./stores/investment.store";
import UIStore from "./stores/ui.store";
import ModalsStore from "./stores/modals.store";
import CurrenciesStore from "./stores/currencies.store";
import OrdersStore from "./stores/order.store";
import TransactionsStore from "./stores/transactions.store";
import LotStatusesStore from "./stores/lotStatuses.store";
import RolesStore from "./stores/roles.store";

axios.defaults.withCredentials = true;

const browserHistory = createBrowserHistory();
const routing = new RouterStore();
export const history = syncHistoryWithStore(browserHistory, routing);

const stores = {
  LocalesStore,
  routing,
  NavigationStore,
  UserStore,
  FormsStore,
  LotsStore,
  InvestmentStore,
  UIStore,
  ModalsStore,
  CurrenciesStore,
  OrdersStore,
  TransactionsStore,
  LotStatusesStore,
  RolesStore
};

ReactDOM.render(
  <React.StrictMode>
    <Provider {...stores}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
