import React, { useEffect, useRef } from "react";
import { observer, inject } from "mobx-react";

// Components
import Block from "../../../components/ui/container/container";
import LotForm from "../../../components/forms/lotForm/lotForm";

// Styles
import styles from "./newLot.module.sass";

const NewLotPage: React.FC = inject(
  "LocalesStore",
  "routing"
)(
  observer(({ routing, LocalesStore }: any) => {
    const { locale } = LocalesStore;
    return (
      <div className={styles.newLot}>
        <Block>
          <h1>{ locale.lotForm.createLotHeader }</h1>
          <hr />
          <LotForm state="new" />
        </Block>
      </div>
    );
  })
);

export default NewLotPage;
