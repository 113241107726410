import { observable, computed, action } from "mobx";

// Types
import { LotStatus } from "../types/lotStatuses";

// API
import { getAllLotStatusesAction } from "../api/lotStatuses";

class LotStatusesStore {
  // Properties
  @observable items: LotStatus[] = [];

  // Computed

  // Actions
  @action async getAllLotStatuses(): Promise<void> {
    try {
      const lotStatuses = await getAllLotStatusesAction();
      if (lotStatuses) this.items = lotStatuses;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new LotStatusesStore();
