import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import moment from "moment";
import { PUBLIC_URL } from "../../../config/config";

// Stores
import lotsStore from "../../../stores/lots.store";

// Components
import {
  Row,
  Col,
  Button,
  Table,
  FormGroup,
  Input,
  Label,
  Badge,
} from "reactstrap";
import Block from "../../../components/ui/container/container";
import PaymentProgress from "../../../components/paymentProgress/paymentProgress";
import { FaFile } from "react-icons/fa";
import Modal from "../../../components/ui/modal/modal";
import MoneyInput from "../../../components/ui/moneyInput/moneyInput";

// Styles
import styles from "./lotDetails.module.sass";

const statusesColorMapping = {
  DRAFT: "secondary",
  MODERATION: "warning",
  ACTIVE: "success",
  EXECUTION: "primary",
  COMPLETED: "success",
  EXPIRED: "danger",
};

const LotDetails: React.FC = inject(
  "routing",
  "UserStore",
  "LotsStore",
  "ModalsStore",
  "LocalesStore"
)(
  observer(({ UserStore, LotsStore, ModalsStore, LocalesStore }: any) => {
    const { item } = LotsStore;
    const { lotId } = useParams<any>();
    const { id } = UserStore.currentUser;
    const { investModal } = ModalsStore;
    const { locale } = LocalesStore;
    useEffect(() => {
      LotsStore.getLotById(lotId);
    }, []);
    useEffect(() => {
      return () => {
        LotsStore.cleanItem();
      };
    }, []);

    return item ? (
      <div>
        <Modal
          title={locale.investments.modals.invest.header}
          hide={investModal.hide.bind(investModal)}
          visible={investModal.visible}
          submitLabel={locale.buttons.invest}
          submitDisabled={!investModal.isValid}
          submit={investModal.submit.bind(investModal, { userId: id, lotId })}
        >
          {" "}
          <FormGroup className={styles.modalContent}>
            <Label for="summ">
              {locale.investments.modals.invest.amountLabel}
            </Label>
              <MoneyInput
                name="summ"
                currencyUnit={'$'}
                value={investModal.summ}
                onChange={(value, data) => {
                  investModal.change(value, item.minPaidSumm)
                }}
                placeholder={locale.investments.modals.invest.amountPlaceholder}
                className={`${!investModal.isValid ? "invalid" : ""}  ${ styles.numberInput }`}
              />
            {investModal.validationMessage && (
              <p className="fieldMessage" data-type={"error"}>
                {investModal.validationMessage}
              </p>
            )}
          </FormGroup>
        </Modal>
        <Block>
          <div className={styles.header}>
            <h1>{item.shortName}</h1>
            <Badge color={statusesColorMapping[item.status.name]}>
              {locale.statuses[item.status.name]}
            </Badge>
          </div>

          <PaymentProgress
            remaining={item.summaryValue - LotsStore.calculateInvestments(item)}
            value={
              (LotsStore.calculateInvestments(item) / item.summaryValue) * 100
            }
            lotStatus={item.status}
            locale={locale}
          />
          <Table striped>
            <tbody>
              <tr>
                <td>{locale.lotDetails.totalInvestment}</td>
                <td>
                  {item.summaryValue} {locale.currencies.USD}
                </td>
              </tr>
              <tr>
                <td>{locale.lotDetails.minimumContribution}</td>
                <td>
                  {item.minPaidSumm} {locale.currencies.USD}
                </td>
              </tr>
              <tr>
                <td>{locale.lotDetails.profit}</td>
                <td>{item.profit}%</td>
              </tr>
            </tbody>
          </Table>
          <hr />
          <div className={styles.fullDescription}>
            <h3>{locale.lotDetails.lotDescription}</h3>
            <div dangerouslySetInnerHTML={{ __html: item.fullDescription }} />
          </div>
          <hr />
          <div>
            <div className={styles.lotDetails}>
              <Col md={6} sm={12}>
                <h3>{locale.lotDetails.roadMap}</h3>
                <ul className={styles.roadMap}>
                  {item.stages.map((stage) => (
                    <li>
                      <div className={styles.roadMapDescription}>
                        <div className={styles.roadMapStageHeader}>
                          <span
                            className={styles.roadMapTitle}
                            dangerouslySetInnerHTML={{
                              __html: stage.title,
                            }}
                          ></span>
                          {stage.status === "NEW" && (
                            <Badge
                              color="warning"
                              dangerouslySetInnerHTML={{
                                __html: locale.stageStatuses[stage.status],
                              }}
                            />
                          )}
                          {stage.status === "ACTIVE" && (
                            <Badge
                              color="primary"
                              dangerouslySetInnerHTML={{
                                __html: locale.stageStatuses[stage.status],
                              }}
                            />
                          )}
                          {stage.status === "COMPLETED" && (
                            <Badge
                              color="success"
                              dangerouslySetInnerHTML={{
                                __html: locale.stageStatuses[stage.status],
                              }}
                            />
                          )}
                        </div>
                        <div className={styles.roadMapStageDescription}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: stage.stageDescription,
                            }}
                          />
                        </div>
                        <div className={styles.roadMapCost}>
                          {`${locale.lotForm.roadmap.fields.cost.label}: ${
                            stage.cost
                              ? `${stage.cost.toFixed(2)} ${
                                  locale.currencies.USD
                                }`
                              : "-"
                          } `}
                        </div>
                        <div className={styles.roadMapStageActions}>
                          {["admin", "operator"].includes(
                            UserStore.currentUser.role
                          ) || UserStore.currentUser.id === item.owner.id}
                          <Button
                            color="primary"
                            disabled={stage.status !== "ACTIVE"}
                            onClick={() =>
                              LotsStore.updateLotStage(item, {
                                ...stage,
                                status: "COMPLETED",
                              })
                            }
                          >
                            {locale.buttons.closeStage}
                          </Button>
                        </div>
                      </div>
                      <span className={styles.roadMapStartDate}>
                        <span
                          className={`${styles.roadMapStatus}`}
                          data-completed={stage.completed}
                          data-funded={stage.funded}
                        ></span>
                        <span className={styles.roadMapStartDateValue}>
                          {moment(stage.startDate).format("DD.MM.YYYY")}
                        </span>
                      </span>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col md={6} sm={12}>
                <h3>{locale.lotDetails.dates}</h3>
                <Table size="sm" striped condensed>
                  <tbody>
                    <tr>
                      <td>{locale.lotDetails.startOfCollection}</td>
                      <td>
                        {moment(item.startOfCollection).isValid()
                          ? moment(item.startOfCollection).format("DD.MM.YYYY")
                          : "Не указан"}
                      </td>
                    </tr>
                    <tr>
                      <td>{locale.lotDetails.endOfCollection}</td>
                      <td>
                        {moment(item.endOfCollection).isValid()
                          ? moment(item.endOfCollection).format("DD.MM.YYYY")
                          : "Не указан"}
                      </td>
                    </tr>
                    <tr>
                      <td>{locale.lotDetails.startOfExecution}</td>
                      <td>
                        {moment(item.startOfExecution).isValid()
                          ? moment(item.startOfExecution).format("DD.MM.YYYY")
                          : "Не указан"}
                      </td>
                    </tr>
                    <tr>
                      <td>{locale.lotDetails.endOfExecution}</td>
                      <td>
                        {moment(item.endOfExecution).isValid()
                          ? moment(item.endOfExecution).format("DD.MM.YYYY")
                          : "Не указан"}
                      </td>
                    </tr>
                    <tr>
                      <td>{locale.lotDetails.returnInvestmentDate}</td>
                      <td>
                        {moment(item.returnInvestmentsDate).isValid()
                          ? moment(item.returnInvestmentDate).format(
                              "DD.MM.YYYY"
                            )
                          : "Не указан"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </div>
          </div>
          <hr />
          <div>
            <h3>{locale.lotDetails.participants}</h3>
            {!item.participiants.length && (
              <p>{locale.lotDetails.noParticipants}</p>
            )}
            <p>
              {item.participiants
                .map((participiant) => participiant.name)
                .join(", ")}
            </p>
          </div>
          <div>
            <h3>{locale.lotDetails.documents}</h3>
            {!item.files.length && <p>{locale.lotDetails.noDocuments}</p>}
            <ul className={styles.documentList}>
              {item.files.map(({ file }) => (
                <li>
                  <FaFile />
                  <span>
                    <a href={`${PUBLIC_URL}${file.publicUrl}`} target="_blank">
                      {file.originalFilename}
                    </a>
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <hr />
          <Button
            color="success"
            size="lg"
            onClick={investModal.show.bind(investModal)}
            disabled={item.status.name !== "ACTIVE"}
          >
            {locale.buttons.invest}
          </Button>
          {item.status.name !== "ACTIVE" && (
            <p className="error">{locale.lotDetails.lotIsInactive}</p>
          )}
        </Block>
      </div>
    ) : null;
  })
);

export default LotDetails;
