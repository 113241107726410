import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Link } from 'react-router-dom';

// Components
import { Card, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap";

// Styles
import styles from "./passwordRecovery.module.sass";

const PasswordRecoveryPage: React.FC = inject()(observer(({ }: any) => {
  useEffect(() => {
    // Contents.getContents();
  }, [])
  
  return (
    <div className={styles.passwordRecovery}>
        <Card className={styles.container}>
          <CardBody>
            <h2>Восстановление пароля</h2>
            <hr />
            <Form className={styles.passwordRecoveryForm}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input type="email" name="email" placeholder="Введите email" />
              </FormGroup>
              <div className={styles.submit}>
                <Button color="primary" size="lg">
                  Восстановить пароль
                </Button>
              </div>
            </Form>
            <hr />
            <div className={styles.signupLink}>
                Если вы впервые на WCT <Link to="/signup">Зарегистрируйтесь</Link>
            </div>
          </CardBody>
        </Card>
      </div>
  )
}))

export default PasswordRecoveryPage;
