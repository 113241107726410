import { GraphQLAPIURL } from "../config/config";
import { AwesomeGraphQLClient } from "awesome-graphql-client";

const client = new AwesomeGraphQLClient({ endpoint: GraphQLAPIURL });

export async function getTransactionsAction({ userId = null }) {
  const request = `#graphql
    query (
      $condition: TransactionWhereInput,
    ){
        allTransactions(where: $condition) {
            id,
            value,
            txId,
            from,
            to,
            type,
            owner{
                id,
                email,
                ethAddress
            },
      }
    }
    `;

  let condition: any = {};

  if (userId) condition.owner = { id: userId };

  const response: any = await client.request(request, {
    condition,
  });

  return response.allTransactions;
}