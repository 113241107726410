import React, { Component, useEffect } from "react";
import { Route, Switch } from "react-router";
import { inject, observer } from "mobx-react";

// pages
import AllOrders from "./orders";
import OrderDetails from "./orderDetails/orderDetails";

const allLots: React.FC = ({}: any) => {
  return (
    <div>
      <Switch>
        <Route path={`/orders`} component={AllOrders} exact />
        <Route path={`/orders/:orderId`} component={OrderDetails} exact />
      </Switch>
    </div>
  );
};

export default allLots;
