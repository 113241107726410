import { observable, computed, action } from "mobx";
import { CookieStorage } from "cookie-storage";

// Stores
import UIStore from "./ui.store";
import LocalesStore from "./locales.store";

// API
import {
  signInAction,
  signUpAction,
  authenticatedUserAction,
  unauthenticateUserAction,
  updateCurrentUserAction
} from "../api/user";

// Types
import { UpdateUserInput } from "../types/user";

const cookieStorage = new CookieStorage();

type signInInput = {
  email?: string;
  password?: string;
};

type signUpInput = {
  email?: string;
  password?: string;
  role?: string;
};

type userType = {
  id?: string;
  email?: string;
  token?: string;
  role?: any;
  balances?: [any]
};
class UserStore {
  // Properties
  @observable currentUser: userType | null = null;

  // Computed
  @computed get loggedIn(): boolean {
    return (this.currentUser && !!this.currentUser.id) || false;
  }

  @computed get isAdmin(): boolean {
    if (this.currentUser && this.currentUser.role.name === "admin") return true;
    return false;
  }

  @action async authenticatedUser(): Promise<void> {
    try {
      const user = await authenticatedUserAction();
      if (user) {
        this.currentUser = user;
      } else {
        this.currentUser = {};
      }
    } catch (error) {
      console.log(error);
    }
  }

  @action async signIn({ email, password }: signInInput): Promise<void> {
    const currentLocale =
      window.localStorage.getItem("wctUserLocale") || "en_US";
    try {
      const authResult = await signInAction({
        email,
        password,
      });

      if (authResult) {
        this.currentUser = authResult["item"];
        UIStore.createFlash({
          type: "success",
          content: `${LocalesStore.locales[currentLocale].data.flashMessages.welcome}, ${authResult["item"].email}`,
        });
      } else {
        UIStore.createFlash({
          type: "danger",
          content:
            LocalesStore.locales[currentLocale].data.flashMessages
              .incorrectCredentials,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  @action async signUp({ email, password, role }: signUpInput): Promise<void> {
    try {
      const newUser = await signUpAction({
        email,
        password,
        role
      });
      if (newUser && newUser.id) {
        this.signIn({ email, password });
      }
      if (newUser.errors) {
        newUser.errors.forEach((error) => {
          UIStore.createFlash({
            type: "danger",
            content: error.message,
          });
        });
      }
    } catch (error: any) {
      UIStore.createFlash({
        type: "danger",
        content: error.toString(),
      });
      console.log(error);
    }
  }

  @action async updateCurrentUser(userData: UpdateUserInput): Promise<void> {
    try {
      const updatedUser = await updateCurrentUserAction(userData);
      if (updatedUser && updatedUser.id) {
        this.currentUser = updatedUser;
      }

      if (updatedUser.errors) {
        updatedUser.errors.forEach((error) => {
          UIStore.createFlash({
            type: "danger",
            content: error.message,
          });
        });
      }
    } catch (error: any) {
      UIStore.createFlash({
        type: "danger",
        content: error.toString(),
      });
      console.log(error);
    }
  }

  @action async logout() {
    const currentLocale =
      window.localStorage.getItem("wctUserLocale") || "en_US";
    try {
      const result = await unauthenticateUserAction();
      if (result.success) {
        UIStore.createFlash({
          type: "success",
          content: LocalesStore.locales[currentLocale].data.flashMessages.bye,
        });
        this.currentUser = null;
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export default new UserStore();
