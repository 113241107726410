import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import md5 from "md5";

// Components
import { Button } from "reactstrap";
import { createAvatar } from "../../../helpers/avatars";

// Styles
import styles from "./profileSnippet.module.sass";

// Assets
import ArrowBottom from "../../../assets/images/icons/arrow-bottom.svg";

// Types
type ProfileSnippetProps = {
  user: any;
  loggedIn: boolean;
  logout: any;
  locale: any;
};

const ProfileSnippetComponent: React.FC<ProfileSnippetProps> = ({
  user,
  loggedIn,
  logout,
  locale,
}: ProfileSnippetProps) => {
  const Avatar = user?.email ? createAvatar(md5(user.email)) : null;

  const usdBalance = useMemo(() => {
    return user?.balances?.find(balance => balance.currency.shortName === 'USD');
  }, [user?.balances] );

  return (
    <div className={styles.profileSnippet}>
      {loggedIn ? (
        <div className={styles.profileSnippetContentWrapper}>
          <span className={styles.userName}>
            {user.email}
            <br />
            {usdBalance && usdBalance.value > 0 && (
              <span>
                {locale.header.balance}: {usdBalance.value.toFixed(2)}
                {usdBalance.currency.symbol}
              </span>
            )}
            <span>
              {usdBalance.value <= 0 && (
                <span>{locale.validatorMessages.noFunds}</span>
              )}
            </span>
          </span>
          {!!Avatar && (
            <div
              className={styles.avatar}
              dangerouslySetInnerHTML={{ __html: Avatar }}
            >
              {/* <SVG /> */}
            </div>
          )}

          <span className={styles.profileMenuButton}>
            <img src={ArrowBottom} />
          </span>
          <Button
            color="primary"
            className={styles.button}
            onClick={() => logout()}
          >
            {locale.buttons.logout}
          </Button>
        </div>
      ) : (
        <div className={styles.profileSnippetContentWrapper}>
          <div>
            <Link to={"/signin"}>
              <Button color="primary" className={styles.button}>
                {locale.header.signInButton}
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileSnippetComponent;
