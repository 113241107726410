import React, { useEffect } from "react";
import { Router, Link } from "react-router-dom";

// Components
import { Navbar, Nav, NavItem, NavLink } from "reactstrap";

// Types
import { MenuItem } from "../../../types/menu";

// Styles
import styles from "./mainmenu.module.sass";

interface MainMenuProps {
  menuItems: MenuItem[];
  setActiveItem: any;
  pathname: any;
  currentUser: any;
  locale: any;
  setMobileMenuActive?: Function;
}

const MainMenuComponent: React.FC<MainMenuProps> = ({
  menuItems,
  setActiveItem,
  pathname,
  currentUser,
  locale,
  setMobileMenuActive
}: MainMenuProps) => {
  useEffect(() => {
    setActiveItem(pathname);
  }, [pathname]);

  return (
    <Navbar className={styles.mainmenu}>
      <Nav>
        {menuItems.map(
          (item) =>
            item.roles?.includes(currentUser.role?.name) && (
              <NavItem
                key={item.id}
                className={`${styles.link} ${item.active ? styles.active : ""}`}
                onClick={() => setMobileMenuActive && setMobileMenuActive(false)}
              >
                <Link to={item.path}>{item.text(locale.header.menuItems[item.name])}</Link>
              </NavItem>
            )
        )}
      </Nav>
    </Navbar>
  );
};

export default MainMenuComponent;
