import { observable, computed, action } from "mobx";

// Types
import { Role } from "../types/roles";

// API
import { getAllRolesAction } from "../api/roles";

class RolesStore {
  // Properties
  @observable items: Role[] = [];

  // Computed

  // Actions
  @action async getAllRoles(): Promise<void> {
    try {
      const roles = await getAllRolesAction();
      if (roles) this.items = roles;
    } catch (error) {
      console.log(error);
    }
  }
  findById(id) {
    return this.items.find((item) => item.id === id);
  }
  findByName(name) {
    return this.items.find((item) => item.name === name);
  }
}

export default new RolesStore();
