import { observable, computed, action } from "mobx";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

// Stores
import LotsStore from "./lots.store";
import UserStore from "./user.store";

// Helpers
import { isRequired, email, password, isRequiredPositiveNumber } from "../helpers/validators";

// Actions
import { uploadFilesAction } from "../api/file";

// Types
import { signInInput, newLotInput, newLotFormInput } from "../types/forms";

import LocalesStore from "../stores/locales.store";
const { locale } = LocalesStore;

class FormsStore {
  // Properties
  @observable lotForm: newLotFormInput = {
    valid: function () {
      const fieldsArray = Object.entries(this.fields).map(
        ([key, field]) => field
      );
      this.participiants.validate();
      this.roadMap.validate();

      return fieldsArray
        .filter((item) => item.validator)
        .every((field) => field.valid);
    },
    restoreForm: (lot, form) => {
      for (let fieldKey in form.fields) {
        form.fields[fieldKey] = {
          ...form.fields[fieldKey],
          value: lot[fieldKey],
          valid: true,
        };
      }
      lot.stages.forEach((stage) => {
        form.roadMap.items.push({
          id: uuidv4(),
          fields: [
            {
              id: uuidv4(),
              name: "id",
              label: "ID",
              placeholder: "",
              value: stage.id,
              valid: true,
              type: "hidden",
              message: null,
              onChange: null,
              validator: null,
            },
            {
              id: uuidv4(),
              name: "index",
              label: "index",
              placeholder: "",
              value: stage.index,
              valid: true,
              type: "hidden",
              message: null,
              onChange: null,
              validator: null,
            },
            {
              id: uuidv4(),
              name: "title",
              label: (value: string): string => value,
              placeholder: (value: string): string => value,
              value: stage.title,
              type: "text",
              valid: true,
              width: 12,
              message: null,
              onChange: function (value) {
                this.value = value;
                const isValid = this.validator(value);
                this.valid = isValid;
              },
              validator: function (value) {
                return true;
              },
            },
            {
              id: uuidv4(),
              name: "startDate",
              label: (value: string): string => value,
              placeholder: (value: string): string => value,
              value: stage.startDate,
              type: "date",
              valid: true,
              width: 4,
              message: null,
              onChange: function (value) {
                this.value = value;
                const isValid = this.validator(value);
                this.valid = isValid;
              },
              validator: function (value) {
                return true;
              },
            },
            {
              id: uuidv4(),
              name: "stageDescription",
              label: (value: string): string => value,
              placeholder: (value: string): string => value,
              value: stage.stageDescription,
              type: "tinymce",
              valid: true,
              width: 12,
              message: null,
              onChange: function (value) {
                this.value = value;
                const isValid = this.validator(value);
                this.valid = isValid;
              },
              validator: function (value) {
                return true;
              },
            },
            {
              id: uuidv4(),
              name: "cost",
              label: (value: string): string => value,
              placeholder: (value: string): string => value,
              value: stage.cost,
              type: "text",
              valid: true,
              width: 4,
              message: null,
              onChange: function (value) {
                this.value = value;
                const isValid = this.validator(value);
                this.valid = isValid;
              },
              validator: function (value) {
                return true;
              },
            },
          ],
        });
      });
      lot.participiants.forEach((participiant) => {
        form.participiants.items.push({
          id: uuidv4(),
          fields: [
            {
              name: "id",
              label: "ID",
              placeholder: "",
              value: participiant.id,
              valid: true,
              type: "hidden",
              message: null,
              onChange: null,
              validator: null,
            },
            {
              id: uuidv4(),
              name: "name",
              label: (value: string): string => value,
              placeholder: (value: string): string => value,
              value: participiant.name,
              type: "text",
              valid: true,
              width: 12,
              message: null,
              onChange: function (e) {
                this.value = e.target.value;
                const isValid = this.validator(e.target.value);
                this.valid = isValid;
              },
              validator: function (value) {
                return true;
              },
            },
          ],
        });
      });
      lot.files.forEach((file) => {
        form.files.items.push(file);
      });
    },
    roadMap: {
      valid: false,
      validate: function () {
        const fieldsArray = this.items.map(item => item.fields).flat(1)
        const isValid = !!fieldsArray.length && fieldsArray.every((field) => field.valid);
        this.valid = isValid;
        return isValid;
      },
      removeItem: function (e, itemForDelete): void {
        e.preventDefault();
        this.items = this.items.filter((item) => item.id !== itemForDelete.id);
      },
      createStage: function (e, form) {
        e.preventDefault();
        console.log(form);
        this.items.push({
          id: uuidv4(),
          fields: [
            {
              id: uuidv4(),
              name: "id",
              label: (value) => value,
              placeholder: (value) => value,
              value: "",
              valid: true,
              type: "hidden",
              message: null,
              onChange: (e) => false,
              validator: null,
            },
            {
              id: uuidv4(),
              name: "index",
              label: (value) => value,
              placeholder: (value) => value,
              value: (() => {
                if(!form.roadMap.items.length) return 1;
                const maxIndex = Math.max(...form.roadMap.items.map(item => {
                  const field = item.fields.find(field => field.name === "index");
                  return field.value;
                }))
                console.log(form.roadMap.items.map(item => {
                  const field = item.fields.find(field => field.name === "index");
                  console.log(field);
                  return field.value;
                }))
                return maxIndex + 1
              })(),
              valid: true,
              type: "hidden",
              message: null,
              onChange: (e) => false,
              validator: null,
            },
            {
              id: uuidv4(),
              name: "title",
              label: (value: string): string => value,
              placeholder: (value: string): string => value,
              value: "",
              type: "text",
              valid: false,
              width: 12,
              message: null,
              onChange: function (value) {
                this.value = value;
                const isValid = this.validator ? this.validator(value) : true;
                this.valid = isValid;
              },
              validator: function (value) {
                return !!value;
              },
            },
            {
              id: uuidv4(),
              name: "startDate",
              label: (value: string): string => value,
              placeholder: (value: string): string => value,
              value: new Date(),
              type: "date",
              valid: false,
              width: 6,
              message: null,
              minDate: form.fields.startOfExecution.value,
              maxDate: form.fields.endOfExecution.value,
              onChange: function (value, lotForm) {
                this.value = value;
                const isValid = this.validator ? this.validator(value, lotForm) : true;
                this.valid = isValid;
                lotForm.roadMap.validate();
              },
              validator: function (value, lotForm) {
                if (!value) return false;
                const date = moment(value);
                const typeError: string = "error";
                const validStatus = true;

                const fieldsArray = lotForm.roadMap.items.map(item => item.fields).flat(1)
                const dateFields = fieldsArray.filter(field => field.name === "startDate");
                const firstStepStartDate = dateFields[0];
                const lastStepStartDate = dateFields[dateFields.length -1];
                const prevField = dateFields[dateFields.findIndex(field => field.id === this.id) - 1 ]
                const nextField = dateFields[dateFields.findIndex(field => field.id === this.id) + 1 ];
                const startOfExecution = lotForm.fields.startOfExecution;

                if (prevField) {
                  const isCorrect = date.isAfter(moment(prevField.value), 'day');
                  if (!isCorrect) {
                    this.message = {
                      type: 'error',
                      content: locale.lotForm.roadmap.fields.startDate.dateBetweenError,
                    }
                    return false;
                  } else {
                    this.message = null;
                  }
                }

                if (nextField) {
                  const isCorrect = date.isBefore(moment(nextField.value), 'day');
                  if (!isCorrect) {
                    nextField.message = {
                      type: 'error',
                      content: locale.lotForm.roadmap.fields.startDate.dateBetweenError,
                    }
                    nextField.valid = false;
                  } else {
                    if (nextField.message && nextField.message.content === locale.lotForm.roadmap.fields.startDate.dateBetweenError) {
                      nextField.message = null;
                      nextField.valid = true;
                    }
                  }
                }

                if (firstStepStartDate.id === this.id && startOfExecution) {
                  const isCheck = date.isSameOrAfter(moment(startOfExecution.value), 'day')
                  if (!isCheck) {
                    this.message = {
                      type: typeError,
                      content:  locale.lotForm.roadmap.fields.startDate.startDateError,
                    }
                    return false;
                  } else {
                    this.message = null;
                  }
                }

                const endOfExecution = lotForm.fields.endOfExecution;
                if (lastStepStartDate.id === this.id && endOfExecution) {
                  const isCheck = date.isSameOrBefore(moment(endOfExecution.value), 'day');
                  if (!isCheck) {
                    this.message = {
                      type: typeError,
                      content:  locale.lotForm.roadmap.fields.startDate.endDateError,
                    }
                    return false;
                  } else {
                    this.message = null;
                  }
                }

                return true;
              },
            },
            {
              id: uuidv4(),
              name: "stageDescription",
              label: (value: string): string => value,
              placeholder: (value: string): string => value,
              value: "",
              type: "tinymce",
              valid: false,
              width: 12,
              message: null,
              onChange: function (value) {
                this.value = value;
                const isValid = this.validator ? this.validator(value) : true;
                this.valid = isValid;
              },
              validator: function (value) {
                return !!value;
              },
            },
            {
              id: uuidv4(),
              name: "cost",
              label: (value: string): string => value,
              placeholder: (value: string): string => value,
              value: "",
              type: "text",
              valid: false,
              width: 4,
              message: null,
              onChange: function (value) {
                this.value = value;
                const isValid = this.validator ? this.validator(value) : true;
                this.valid = isValid;
              },
              validator: function (value) {
                return !!value;
              },
            },
          ],
        });
      },
      items: [],
    },
    clearForm: function (form) {
      for (let fieldKey in form.fields) {
        form.fields[fieldKey] = {
          ...form.fields[fieldKey],
          value: "",
          valid: false,
        };
      }
      form.participiants.items = [];
      form.roadMap.items = [];
      form.files.items = [];
    },
    participiants: {
      valid: false,
      validate: function () {
        const fieldsArray = this.items.map(item => item.fields).flat(1)
        const isValid = !!fieldsArray.length && fieldsArray.every((field) => field.valid);
        this.valid = isValid;
        return isValid;
      },
      removeItem: function (e, itemForDelete): void {
        e.preventDefault();
        this.items = this.items.filter((item) => item.id !== itemForDelete.id);
      },
      createParticipiant: function (e) {
        e.preventDefault();
        this.items.push({
          id: uuidv4(),
          fields: [
            {
              name: "id",
              label: (value) => value,
              placeholder: (value) => value,
              value: "",
              valid: true,
              type: "hidden",
              message: null,
              onChange: (e) => false,
              validator: null,
            },
            {
              id: uuidv4(),
              name: "name",
              label: (value: string): string => value,
              placeholder: (value: string): string => value,
              value: "",
              type: "text",
              valid: false,
              width: 12,
              message: null,
              onChange: function (e) {
                this.value = e.target.value;
                const isValid = this.validator
                  ? this.validator(e.target.value)
                  : true;
                this.valid = isValid;
              },
              validator: function (value) {
                return !!value;
              },
            },
          ],
        });
      },
      items: [],
    },
    files: {
      uploadFiles: async function (files: any[]) {
        const uploadedFile = await uploadFilesAction(files);
        this.items.push(uploadedFile);
      },
      items: [],
    },
    onSubmit: async function (e, action: string = "create") {
      e.preventDefault();
      const formData: newLotInput = {
        id: null,
        shortName: "",
        fullName: "",
        summaryValue: 0,
        minPaidSumm: 0,
        profit: 0,
        shortDescription: "",
        fullDescription: "",
        startOfCollection: null,
        endOfCollection: null,
        startOfExecution: null,
        endOfExecution: null,
        returnInvestmentsDate: null,
      };

      const fieldsArray = Object.entries(this.fields).map(
        ([key, field]) => field
      );
      fieldsArray.forEach((field) => {
        if (
          [
            "startOfCollection",
            "endOfCollection",
            "startOfExecution",
            "endOfExecution",
            "returnInvestmentsDate",
          ].includes(field.name)
        ) {
          formData[field.name] = moment(field.value).format("YYYY-MM-DD");
        } else {
          formData[field.name] = field.value;
        }
      });

      const stages = this.roadMap.items.map((item) => {
        return {
          id: item.fields[0].value,
          index: item.fields[1].value,
          title: item.fields[2].value,
          startDate: moment(item.fields[3].value).format("YYYY-MM-DD"),
          stageDescription: item.fields[4].value,
          cost: +item.fields[5].value
        };
      });

      const participiants = this.participiants.items.map((item) => {
        return {
          id: item.fields[0].value,
          name: item.fields[1].value,
        };
      });

      const files = this.files.items;

      formData.stages = stages;
      formData.participiants = participiants;
      formData.files = files;
      
      if (action === "create") {
        const result = await LotsStore.createNewLot(formData);
        return result;
      } else if (action === "update") {
        const result = await LotsStore.updateLot(formData);
        return result;
      }
    },
    validate: function (): boolean {
      return true;
    },
    fields: {
      id: {
        name: "id",
        label: (value) => value,
        placeholder: (value) => value,
        value: "",
        valid: true,
        type: "hidden",
        message: null,
        onChange: (e) => false,
        validator: null,
      },
      shortName: {
        name: "shortName",
        label: (value: string): string => value,
        placeholder: (value: string): string => value,
        value: "",
        valid: false,
        type: "text",
        message: null,
        onChange: function (e) {
          this.value = e.target.value;
          if (this.validator) {
            this.validator(e.target.value, this);
          }
        },
        validator: isRequired,
      },
      fullName: {
        name: "fullName",
        label: (value: string): string => value,
        placeholder: (value: string): string => value,
        value: "",
        valid: false,
        type: "text",
        message: null,
        onChange: function (e) {
          this.value = e.target.value;
          if (this.validator) {
            this.validator(e.target.value, this);
          }
        },
        validator: isRequired,
      },
      summaryValue: {
        name: "summaryValue",
        label: (value: string): string => value,
        placeholder: (value: string): string => value,
        value: "",
        valid: false,
        type: "number",
        message: null,
        onChange: function (e) {
          this.value = e.target.value;
          if (this.validator) {
            this.validator(e.target.value, this);
          }
        },
        validator: isRequiredPositiveNumber,
      },
      minPaidSumm: {
        name: "minPaidSumm",
        label: (value: string): string => value,
        placeholder: (value: string): string => value,
        value: "",
        valid: false,
        type: "number",
        message: null,
        onChange: function (e) {
          this.value = e.target.value;
          if (this.validator) {
            this.validator(e.target.value, this);
          }
        },
        validator: isRequiredPositiveNumber,
      },
      profit: {
        name: "profit",
        label: (value: string): string => value,
        placeholder: (value: string): string => value,
        value: "",
        valid: false,
        type: "number",
        message: null,
        onChange: function (e) {
          this.value = e.target.value;
          if (this.validator) {
            this.validator(e.target.value, this);
          }
        },
        validator: isRequiredPositiveNumber,
      },
      shortDescription: {
        name: "shortDescription",
        label: (value: string): string => value,
        placeholder: (value: string): string => value,
        value: "",
        valid: false,
        type: "tinymce",
        message: null,
        onChange: function (value) {
          this.value = value;
          if (this.validator) {
            this.validator(value, this);
          }
        },
        validator: isRequired,
      },
      fullDescription: {
        name: "fullDescription",
        label: (value: string): string => value,
        placeholder: (value: string): string => value,
        value: "",
        valid: false,
        type: "tinymce",
        message: null,
        onChange: function (value) {
          this.value = value;
          if (this.validator) {
            this.validator(value, this);
          }
        },
        validator: isRequired,
      },
      startOfCollection: {
        name: "startOfCollection",
        label: (value: string): string => value,
        placeholder: (value: string): string => value,
        value: "",
        valid: false,
        type: "date",
        message: null,
        onChange: function (value, form) {
          form.fields.startOfCollection.value = value;
          const minDate = moment(value).add(1, 'd').toDate();

          form.fields.endOfCollection.minDate = minDate;
          form.fields.endOfCollection.validator(form.fields.endOfCollection.value)

          form.fields.startOfExecution.minDate = minDate;
          form.fields.startOfExecution.validator(form.fields.startOfExecution.value)

          form.fields.endOfExecution.minDate = minDate;
          form.fields.endOfExecution.validator(form.fields.endOfExecution.value)

          form.fields.returnInvestmentsDate.minDate = minDate;
          form.fields.returnInvestmentsDate.validator(form.fields.returnInvestmentsDate.value)


          if (this.validator) {
            if (value) {
              this.validator(value.toString(), this);
            } else {
              this.validator("", this);
            }
          }
        },
        validator: isRequired,
      },
      endOfCollection: {
        name: "endOfCollection",
        label: (value: string): string => value,
        placeholder: (value: string): string => value,
        value: "",
        valid: false,
        type: "date",
        message: null,
        minDate: undefined,
        checkForDisable: (field) => {
          return !moment(this.lotForm.fields.startOfCollection.value).isValid();
        },
        onChange: function (value, form) {
          form.fields.endOfCollection.value = value;
          const minDate = moment(value).add(1, 'd').toDate();

          form.fields.startOfExecution.minDate = minDate;
          form.fields.startOfExecution.validator(form.fields.startOfExecution.value);

          form.fields.endOfExecution.minDate = minDate;
          form.fields.endOfExecution.validator(form.fields.endOfExecution.value);

          form.fields.returnInvestmentsDate.minDate = minDate;
          form.fields.returnInvestmentsDate.validator(form.fields.returnInvestmentsDate.value);

          if (this.validator) {
            this.validator(value, this);
          }
        },
        validator: (value) => {
          const lotForm = this.lotForm;
          if (!value || moment(value).isBefore(moment(lotForm.fields.endOfCollection.minDate))) {
            lotForm.fields.endOfCollection.valid = false;
            lotForm.fields.endOfCollection.message = null;
            return;
          }
          if (!moment(lotForm.fields.startOfCollection.value).isValid()) {
            lotForm.fields.endOfCollection.valid = false;
            lotForm.fields.endOfCollection.message = {
              type: "error",
              content: "Please set start of collection date",
            };
          } else if (
            +new Date(lotForm.fields.startOfCollection.value) > +new Date(value)
          ) {
            lotForm.fields.endOfCollection.valid = false;
            lotForm.fields.endOfCollection.message = {
              type: "error",
              content:
                "End of collection date can't be earlier then start of collection date",
            };
          } else {
            lotForm.fields.endOfCollection.valid = true;
            lotForm.fields.endOfCollection.message = null;
          }
        },
      },
      startOfExecution: {
        name: "startOfExecution",
        label: (value: string): string => value,
        placeholder: (value: string): string => value,
        value: "",
        valid: false,
        type: "date",
        message: null,
        onChange: function (value, form) {
          form.fields.startOfExecution.value = value;
          const minDate = moment(value).add(1, 'd').toDate()

          form.fields.endOfExecution.minDate = minDate;
          form.fields.endOfExecution.validator(form.fields.endOfExecution.value);

          form.fields.returnInvestmentsDate.minDate = minDate;
          form.fields.returnInvestmentsDate.validator(form.fields.returnInvestmentsDate.value);
          if (this.validator) {
            this.validator(value, this);
          }

          const fieldsArray = form.roadMap.items.map(item => item.fields).flat(1)
          const dateFields = fieldsArray.filter(field => field.name === "startDate");
          dateFields.forEach(field => {
            field.minDate = value;
            field.valid = field.validator(field.value, form);
          });
        },
        checkForDisable: (field) => {
          return !moment(this.lotForm.fields.endOfCollection.value).isValid();
        },
        validator: (value) => {
          const lotForm = this.lotForm;
          if (!value || moment(value).isBefore(moment(lotForm.fields.startOfExecution.minDate))) {
            lotForm.fields.startOfExecution.valid = false;
            lotForm.fields.startOfExecution.message = null;
            return;
          }
          if (!moment(lotForm.fields.endOfCollection.value).isValid()) {
            lotForm.fields.startOfExecution.valid = false;
            lotForm.fields.startOfExecution.message = {
              type: "error",
              content: "Please set end of collection date",
            };
          } else if (
            +new Date(lotForm.fields.endOfCollection.value) > +new Date(value)
          ) {
            lotForm.fields.startOfExecution.valid = false;
            lotForm.fields.startOfExecution.message = {
              type: "error",
              content:
                "Start of execution date can't be earlier then end of collection date",
            };
          } else {
            lotForm.fields.startOfExecution.valid = true;
            lotForm.fields.startOfExecution.message = null;
          }
        },
      },
      endOfExecution: {
        name: "endOfExecution",
        label: (value: string): string => value,
        placeholder: (value: string): string => value,
        value: "",
        valid: false,
        type: "date",
        message: null,
        onChange: function (value, form) {
          form.fields.endOfExecution.value = value;

          const minDate = moment(value).add(1, 'd').toDate()
          form.fields.returnInvestmentsDate.minDate = minDate;
          form.fields.returnInvestmentsDate.validator(form.fields.returnInvestmentsDate.value)

          if (this.validator) {
            this.validator(value, this);
          }
          const fieldsArray = form.roadMap.items.map(item => item.fields).flat(1)
          const dateFields = fieldsArray.filter(field => field.name === "startDate");
          dateFields.forEach(field => {
            field.maxDate = value;
            field.valid = field.validator(field.value, form);
          });
        },
        checkForDisable: (field) => {
          return !moment(this.lotForm.fields.startOfExecution.value).isValid();
        },
        validator: (value) => {
          const lotForm = this.lotForm;
          if (!value || moment(value).isBefore(moment(lotForm.fields.endOfExecution.minDate))) {
            lotForm.fields.endOfExecution.valid = false;
            lotForm.fields.endOfExecution.message = null;
            return;
          }
          if (!moment(lotForm.fields.startOfExecution.value).isValid()) {
            lotForm.fields.endOfExecution.valid = false;
            lotForm.fields.endOfExecution.message = {
              type: "error",
              content: "Please set start of execution date",
            };
          } else if (
            +new Date(lotForm.fields.startOfExecution.value) > +new Date(value)
          ) {
            lotForm.fields.endOfExecution.valid = false;
            lotForm.fields.endOfExecution.message = {
              type: "error",
              content:
                "End of execution date can't be earlier then start of execution date",
            };
          } else {
            lotForm.fields.endOfExecution.valid = true;
            lotForm.fields.endOfExecution.message = null;
          }
        },
      },
      returnInvestmentsDate: {
        name: "returnInvestmentsDate",
        label: (value: string): string => value,
        placeholder: (value: string): string => value,
        value: "",
        valid: false,
        type: "date",
        message: null,
        onChange: function (value) {
          this.value = value;
          if (this.validator) {
            this.validator(value, this);
          }
        },
        checkForDisable: (field) => {
          return !moment(this.lotForm.fields.endOfExecution.value).isValid();
        },
        validator: (value) => {
          const lotForm = this.lotForm;
          if (!value || moment(value).isBefore(moment(lotForm.fields.returnInvestmentsDate.minDate))) {
            lotForm.fields.returnInvestmentsDate.valid = false;
            lotForm.fields.returnInvestmentsDate.message = null;
            return;
          }
          if (!moment(lotForm.fields.endOfExecution.value).isValid()) {
            lotForm.fields.returnInvestmentsDate.valid = false;
            lotForm.fields.returnInvestmentsDate.message = {
              type: "error",
              content: "Please set end of execution date",
            };
          } else if (
            +new Date(lotForm.fields.endOfExecution.value) > +new Date(value)
          ) {
            lotForm.fields.returnInvestmentsDate.valid = false;
            lotForm.fields.returnInvestmentsDate.message = {
              type: "error",
              content:
                "Return investments date can't be earlier then end of execution date",
            };
          } else {
            lotForm.fields.returnInvestmentsDate.valid = true;
            lotForm.fields.returnInvestmentsDate.message = null;
          }
        },
      },
    },
  };
  @observable signInForm: any = {
    valid: false,
    onSubmit: function (e) {
      e.preventDefault();
      const formData: signInInput = {};
      this.fields.forEach((field) => {
        formData[field.name] = field.value;
      });
      UserStore.signIn(formData);
    },
    validate: function () {
      return true;
    },
    fields: [
      {
        name: "email",
        label: (value) => value,
        placeholder: (value) => value,
        value: "",
        valid: false,
        type: "email",
        message: null,
        onChange: function (e) {
          this.value = e.target.value;
          this.validator(e.target.value, this);
        },
        validator: email,
      },
      {
        name: "password",
        label: (value) => value,
        placeholder: (value) => value,
        value: "",
        valid: false,
        type: "password",
        message: null,
        onChange: function (e) {
          this.value = e.target.value;
          this.validator(e.target.value, this);
        },
        validator: password,
      },
    ],
  };
  @observable signUpForm: any = {
    onSubmit: function (e) {
      e.preventDefault();
      if(this.isValid()) {
        const formData: signInInput = {};
        this.fields.forEach((field) => {
          formData[field.name] = field.value;
        });
        UserStore.signUp(formData);
      }
    },
    isValid: function () {
      this.fields.forEach(field => {field.validator(field.value, field)})
      return this.fields.every(field => field.valid);
    },
    fields: [
      {
        name: "role",
        label: (value) => value,
        placeholder: (value) => value,
        value: null,
        valid: false,
        type: "radio",
        message: null,
        onChange: function (value) {
          this.value = value;
          this.validator(value, this);
        },
        validator: isRequired,
      },
      {
        name: "email",
        label: (value) => value,
        placeholder: (value) => value,
        value: "",
        valid: false,
        type: "email",
        message: null,
        onChange: function (e) {
          this.value = e.target.value;
          this.validator(e.target.value, this);
          console.log(this.message);
        },
        validator: email,
      },
      {
        name: "password",
        label: (value) => value,
        placeholder: (value) => value,
        value: "",
        valid: false,
        type: "password",
        message: null,
        onChange: function (e, message) {
          this.value = e.target.value;
          this.validator(e.target.value, this);
        },
        validator: password,
      },
      {
        name: "confirmPassword",
        label: (value) => value,
        placeholder: (value) => value,
        value: "",
        valid: false,
        type: "password",
        message: null,
        onChange: function (e) {
          this.value = e.target.value;
          this.validator(e.target.value, this);
        },
        validator: password,
      },
    ],
  };

  // Computed

  // Actions
}

export default new FormsStore();
