import { GraphQLAPIURL } from "../config/config";
import { AwesomeGraphQLClient } from "awesome-graphql-client";

const client = new AwesomeGraphQLClient({ endpoint: GraphQLAPIURL });

export async function getAllLotStatusesAction() {
    const request = `#graphql
  query {
    allLotStatuses {
      id,
      name
    }
  }
  `;
  const response: any = await client.request(request);

  return response.allLotStatuses;
}
