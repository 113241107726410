import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Styles
import styles from "./container.module.sass";

type ModalProps = {
  hide: Function;
  visible: boolean;
  title?: string;
  children: any;
  submit?: Function | null;
  submitLabel?: string;
  submitDisabled?: boolean;
  closeLabel?: string;
};

const ModalComponent: React.FC<ModalProps> = inject("ModalsStore")(
  observer(
    ({
      hide,
      visible,
      title,
      children,
      submit = null,
      submitLabel = "Ок",
      submitDisabled = false,
      closeLabel = "Cancel",
    }: ModalProps) => {
      return (
        <Modal isOpen={visible}>
          {!!title && <ModalHeader>{title}</ModalHeader>}
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            {!!submit && (
              <Button color="primary" onClick={(e, ...args) => submit(e, args)} disabled={submitDisabled}>
                {submitLabel}
              </Button>
            )}
            <Button color="secondary" onClick={() => hide()}>
              {closeLabel}
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
  )
);

export default ModalComponent;
