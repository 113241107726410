import { GraphQLAPIURL } from "../config/config";
import { AwesomeGraphQLClient } from "awesome-graphql-client";
import axios from "axios";

export async function uploadFilesAction(files: any[]) {
  const client = new AwesomeGraphQLClient({ endpoint: GraphQLAPIURL });
  const request = `#graphql
    mutation ($file: Upload!) {
        createUploadedFile(data: { file: $file }) {
            id,
            file {
                id,
                filename,
                originalFilename,
                publicUrl
            }
        }
    }
    `;

  const response: any = await client.request(request, {
    file: files[0],
  });

  return response.createUploadedFile;
}
