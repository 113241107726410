import React from "react";
import { observer, inject } from "mobx-react";

// Components
import Block from "../../../components/ui/container/container";
import LotForm from "../../../components/forms/lotForm/lotForm";

// Styles
import styles from "./editLot.module.sass";

const EditLotPage: React.FC = inject(
  "routing"
)(
  observer(({ routing, match }: any) => {
    return (
      <div className={styles.editLot}>
        <Block>
          <h1>Редактировать лот</h1>
          <hr />
          <LotForm state="edit" lotId={match.params.lotId} />
        </Block>
      </div>
    );
  })
);

export default EditLotPage;
