import React, { useEffect } from "react";
import { Redirect, Route } from "react-router";
import { inject, observer } from "mobx-react";
import { Button } from "reactstrap";

// components
import Header from "./components/header/header";
import PrivateRoute from "./helpers/privateRoute";
import Flashes from "./components/ui/flashes/flashes";

// pages
import Mainpage from "./pages/mainpage/mainpage";
import AllLots from "./pages/allLots/";
import Investments from "./pages/investments/investments";
import Wallet from "./pages/wallet/wallet";
import Profile from "./pages/profile/profile";
import SignUp from "./pages/signup/signup";
import SignIn from "./pages/signin/signin";
import PasswordRecovery from "./pages/passwordRecovery/passwordRecovery";
import AllOrders from "./pages/orders/";
import MetaMask from "./services/metamask.service";
import { defaultNavigationPaths } from "./stores/navigation.store";
// styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.sass";
import "./ui.sass";
import AnnouncmentComponent from "./components/announcement/announcement";

// Helpers
import getBrowser from "./helpers/checkBrowser";
import { locale } from "moment";

const App: React.FC = inject(
  "routing",
  "NavigationStore",
  "UserStore",
  "InvestmentStore",
  "UIStore",
  "CurrenciesStore",
  "LocalesStore",
  "LotStatusesStore",
  "RolesStore"
)(
  observer(
    ({
      routing,
      NavigationStore,
      UserStore,
      CurrenciesStore,
      LocalesStore,
      LotStatusesStore,
      RolesStore
    }: any) => {
      useEffect(() => {
        UserStore.authenticatedUser();
      }, [UserStore.loggedIn]);
      useEffect(() => {
        CurrenciesStore.getAllCurrencies();
      }, [CurrenciesStore.items.length]);
      useEffect(() => {
        LotStatusesStore.getAllLotStatuses();
      }, [LotStatusesStore.items.length]);
      useEffect(() => {
        RolesStore.getAllRoles();
      }, [RolesStore.items.length]);
      useEffect(() => {
        LocalesStore.getLocale();
      }, []);
      const { locale } = LocalesStore;
      const defaultChecker = () => {
        const { role } = UserStore.currentUser;
        const defaultPath = role && defaultNavigationPaths[role.name];
        return (defaultPath) ? (<Redirect to={defaultPath}/>) : (<Mainpage />);
      }

      if (UserStore.loggedIn) MetaMask.init();
      const browser = getBrowser();

      const { menuItems, setActiveItem } = NavigationStore;
      return (
        LocalesStore.currentLocale && (
          <div className="App">
            <Flashes />
            <Header
              pathname={routing.location.pathname}
              menuItems={menuItems}
              setActiveItem={setActiveItem.bind(NavigationStore)}
              currentUser={UserStore.currentUser}
              loggedIn={UserStore.loggedIn}
              logout={UserStore.logout.bind(UserStore)}
              locale={LocalesStore.locale}
            />
            {UserStore.currentUser && (
              <div className="content-container">
                {!MetaMask.pluginInstalled() &&
                  UserStore.loggedIn &&
                  (["chrome", "firefox"].includes(browser) ? (
                    <AnnouncmentComponent type="danger">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {locale.announcements.metamaskNotInstalled}
                        {browser === "chrome" && (
                          <a
                            href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=ru"
                            target="_blank"
                          >
                            <Button color="primary">
                              {locale.buttons.installMetaMask}
                            </Button>
                          </a>
                        )}
                        {browser === "firefox" && (
                          <a
                            href="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search"
                            target="_blank"
                          >
                            <Button color="primary">
                              {locale.buttons.installMetaMask}
                            </Button>
                          </a>
                        )}
                      </div>
                    </AnnouncmentComponent>
                  ) : (
                    <AnnouncmentComponent type="danger">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        Unfortunately your browser is not supporting web3 app
                        and metamask. Please install and use Chrome or Firefox
                        browser.
                      </div>
                    </AnnouncmentComponent>
                  ))}
                <Route exact path={`/`} component={defaultChecker} />
                <Route path={"/signup"} component={SignUp} />
                <Route path={"/signin"} component={SignIn} />
                <Route
                  path={"/passwordRecovery"}
                  component={PasswordRecovery}
                />
                <PrivateRoute path={`/lots`} component={AllLots} />
                <PrivateRoute path={`/investments`} component={Investments} />
                <PrivateRoute path={`/wallet`} component={Wallet} />
                <PrivateRoute path={`/profile`} component={Profile} />
                <PrivateRoute path={`/orders`} component={AllOrders} />
              </div>
            )}
          </div>
        )
      );
    }
  )
);

export default App;
