import LocalesStore from "../stores/locales.store";

const { locale } = LocalesStore;

export function isRequired(value: string | null, field: any): boolean {
  const isValid: boolean = !!value && value.length > 0;
  const message: string | null = isValid ? null : locale.validatorMessages.required;
  const type: string = "error";
  field.message = !isValid
    ? {
        type: type,
        content: message,
      }
    : null;
  field.valid = isValid;
  return isValid;
}

export function isRequiredPositiveNumber(value: string, field: any): void {
  const message = locale.validatorMessages.numberPositiveInvalid;
  const isRequiredValid = isRequired(value, field);
  if (isRequiredValid && parseFloat(value) <= 0) {
    field.message = {
        type: "error",
        content: message
      }
    field.valid = false;
  }
}

export function email(value: string, field: any): void {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid: boolean = re.test(String(value).toLowerCase());
    const message: string | null = isValid ? null : locale.validatorMessages.email;
    const type: string = "error";
    field.message = !isValid
      ? {
          type: type,
          content: message,
        }
      : null;
    field.valid = isValid;
}

export const password = (value: string, field: any): void => {
    const passwordLength = 8;
    const isValid: boolean = value.length >= passwordLength;
    const message: string | null = isValid ? null : locale.validatorMessages.password;
    const type: string = "error";
    field.message = !isValid
      ? {
          type: type,
          content: message,
        }
      : null;
    field.valid = isValid;
}
