import { GraphQLAPIURL } from "../config/config";
import axios from "axios";
import escapeQuotes from "../helpers/escapeQuote";
import { AwesomeGraphQLClient } from "awesome-graphql-client";

// Types
import { UpdateUserInput } from "../types/user";

const client = new AwesomeGraphQLClient({ endpoint: GraphQLAPIURL });

export async function signUpAction({ email, password, role }) {
  const query = `#graphql
  mutation {
    createUser(data: {
      email: "${escapeQuotes(email)}",
      password: "${escapeQuotes(password)}",
      role: {
        connect: {
          id: "${escapeQuotes(role)}"
        }
      }
    }) {
      id,
      email,
      ethAddress,
      role {
        name
      }
    }
  }
  `;
  const response = await axios
    .post(GraphQLAPIURL, {
      query,
    })
    .then((response) => response.data);
    if(response.errors) return response;
    return response.data.createUser
}

export async function signInAction({ email, password }) {
  const query = `#graphql
  mutation {
    authenticateUserWithPassword(email: "${escapeQuotes(email)}", password: "${escapeQuotes(password)}") {
      token,
      item {
          id,
          email,
          ethAddress,
          role {
            name
          },
          balances {
            value,
            currency {
              shortName,
              fullName,
              symbol
            }
          }
      }
    }
  }
  `;
  const response = await axios
    .post(GraphQLAPIURL, {
      query,
    })
    .then((response) => response.data);

  return response.data.authenticateUserWithPassword;
}

export async function authenticatedUserAction() {
  const query = `#graphql
    {
      authenticatedUser {
        id,
        email,
        ethAddress,
        role {
          name
        },
        balances {
            value,
            currency {
              shortName,
              fullName,
              symbol
            }
          }
      }
    }
  `;

  const response = await axios
    .post(GraphQLAPIURL, {
      query,
    })
    .then((response) => response.data);
  return response.data.authenticatedUser;
}

export async function unauthenticateUserAction() {
  const query = `#graphql
    mutation {
      unauthenticateUser {
        success
      }
    }
  `;

  const response = await axios
    .post(GraphQLAPIURL, {
      query,
    })
    .then((response) => response.data);

  return response.data.unauthenticateUser;
}

export async function updateCurrentUserAction({
  id,
  email,
  password,
  ethAddress
}: UpdateUserInput) {
  let variables: any = {
    id,
    data: {},
  };
  if (email) variables.data.email = email;
  if (password) variables.data.password = password;
  if (ethAddress) variables.data.ethAddress = ethAddress;
  
  const request = `#graphql
    mutation(
      $id: ID!,
      $data: UserUpdateInput
    ) {
      updateUser(id: $id, data: $data) {
        id,
        email,
        ethAddress,
        role {
          name
        },
        balances {
          value,
          currency {
            shortName,
            fullName,
            symbol
          }
        }
      }
    }
    `;
  const response: any = await client.request(request, variables);

  return response.updateUser;
}