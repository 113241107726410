import { observable, computed, action } from "mobx";
import { resolve } from "dns";

// API
import {
  getInvestmentsAction,
  createInvestmentAction,
} from "../api/investment";

// Stores
import UIStore from "./ui.store";
import LotsStore from "./lots.store";
import CurrenciesStore from "./currencies.store";
import UserStore from "./user.store";

// Types

class InvestmentStore {
  // Properties
  @observable items: any[] = [];
  @observable groupedInvestments: Object = {};

  // Computed

  // Actions
  @action async getInvestments({ userId, lotId }: any): Promise<any | null> {
    const investments = await getInvestmentsAction({ userId, lotId });
    const groupedInvestments = {};

    investments.forEach((investment) => {
      if (investment.lot) {
        if (!groupedInvestments[investment.lot.id]) {
          groupedInvestments[investment.lot.id] = {
            items: [],
          };
        }
        groupedInvestments[investment.lot.id].items.push(investment);
      }
    });

    for (let lotId in groupedInvestments) {
      const lotInvestments = groupedInvestments[lotId];
      lotInvestments.summary = lotInvestments.items.reduce((acc, current) => {
        return acc + current.value;
      }, 0);
    }

    this.groupedInvestments = groupedInvestments;
    this.items = investments;
  }

  @action async createInvestment(params) {
    const currency: any = CurrenciesStore.getCurrencyByName("USD");
    const newInvestment = await createInvestmentAction({
      ...params,
      currencyId: currency.id,
    });
    if (newInvestment && LotsStore.item) {
      LotsStore.item = {
        ...LotsStore.item,
        investments: [
          ...LotsStore.item.investments,
          newInvestment,
        ],
        status: newInvestment.lot.status
      }
    }

    this.items.push(newInvestment);
    return newInvestment;
  }
}

export default new InvestmentStore();
