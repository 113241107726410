import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import { defaultNavigationPaths } from '../../stores/navigation.store';
// Components
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

// Styles
import styles from "./signin.module.sass";

const SignInPage: React.FC = inject(
  "routing",
  "UserStore",
  "FormsStore",
  "LocalesStore"
)(
  observer(({ routing, UserStore, FormsStore, LocalesStore }: any) => {
    const { signInForm } = FormsStore;
    const { locale } = LocalesStore;
    useEffect(() => {
      if (UserStore.loggedIn) {
        const { role } = UserStore.currentUser;
        routing.replace(defaultNavigationPaths[role.name] || '/lots');
      }
    }, [UserStore.loggedIn]);

    return (
      <div className={styles.signin}>
        <Card className={styles.container}>
          <CardBody>
            <h2>{locale.signInForm.header}</h2>
            <hr />
            <Form
              className={styles.signinForm}
              onSubmit={(e) => signInForm.onSubmit(e)}
            >
              {signInForm.fields.map((field) => (
                <FormGroup>
                  <Label for={field.name}>{field.placeholder(locale.signInForm.fields[field.name].label)}</Label>
                  <Input
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder(locale.signInForm.fields[field.name].placeholder)}
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                    className={!field.valid ? "invalid" : ""}
                  />
                  {field.message && (
                    <p className="fieldMessage" data-type={field.message.type}>
                      {field.message.content}
                    </p>
                  )}
                </FormGroup>
              ))}
              <div className={styles.submit}>
                <Button color="primary" size="lg">
                  {locale.signInForm.signInButton}
                </Button>
              </div>
            </Form>
            <div className={styles.forgotPassword}>
              <Link to="/passwordRecovery">
                {locale.signInForm.forgotPassword}
              </Link>
            </div>
            <hr />
            <div className={styles.signupLink}>
              {locale.signInForm.firstTimeText}{" "}
              <Link to="/signup">{locale.signInForm.firstTimeLink}</Link>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  })
);

export default SignInPage;
