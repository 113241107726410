import { observable, action } from "mobx";
import { v4 as uuidv4 } from 'uuid';

type Flash = {
    id?: string;
    type: string;
    content: string;
}

class UIStore {
  // Properties
  @observable flashes: Flash[] = [];

  // Computed

  // Actions
  @action createFlash(flash: Flash): void {
      const id = uuidv4();
      this.flashes.push({
          ...flash,
          id
      })
      setTimeout(() => {
        this.removeFlash(id)
      }, 5000);
  }

  @action removeFlash(flashId): void {
    this.flashes = this.flashes.filter(flash => flash.id !== flashId);
  }
}

export default new UIStore();
