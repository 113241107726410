import React, { useRef, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, TinyMCEAPIKey } from "../../../config/config";
import moment from "moment";

// Components
import { Form, FormGroup, Label, Col, Input, Button } from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";
import { FaFile } from "react-icons/fa";
import * as Locales from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import NumberFormat from "react-number-format";
import DateInput from "../../ui/dateInput/dateInput"

// Styles
import styles from "./lotForm.module.sass";

// Types
import { formField } from "../../../types/forms";

const LotForm: React.FC<any> = inject(
  "routing",
  "FormsStore",
  "LotsStore",
  "LocalesStore"
)(
  observer(
    ({ routing, FormsStore, LotsStore, LocalesStore, lotId, state }: any) => {
      useEffect(() => {
        if (state === "edit") {
          const getLot = async (lotId) => {
            const lot = await LotsStore.getLotById(lotId);
            FormsStore.lotForm.restoreForm(lot, FormsStore.lotForm);
          };
          getLot(lotId);
        }
        return () => {
          FormsStore.lotForm.clearForm(FormsStore.lotForm);
        };
      }, []);
      const { lotForm } = FormsStore;
      const lotFormFields = Object.entries(lotForm.fields).map(
        ([key, field]) => field as formField
      );
      const { locale } = LocalesStore;
      const uploadFileInput: any = useRef(null);
      const submitHandler = async (e) => {
        const success =
          state === "new"
            ? await lotForm.onSubmit(e, "create")
            : await lotForm.onSubmit(e, "update");
        if (success) {
          routing.push("/lots");
        }
      };
      const uploadFile = () => {
        uploadFileInput.current.click();
      };
      const fileUploadChangeHandler = (e) => {
        const files = e.target.files;
        FormsStore.lotForm.files.uploadFiles(files);
      };

      return (
        <Form onSubmit={(e) => submitHandler(e)}>
          <h4>{locale.lotForm.commonInformation.header}</h4>
          {lotFormFields.map((field) => (
            <FormGroup row key={field.name}>
              <Col md={4}>
                {field.type !== "hidden" && (
                  <Label for={field.name}>
                    {field.label(
                      locale.lotForm.commonInformation.fields[field.name].label
                    )}
                  </Label>
                )}
              </Col>
              <Col md={8}>
                {field.type === "tinymce" && (
                  <div className={!field.valid ? styles.lotEditorInvalid : "" }>
                    <Editor
                      apiKey={TinyMCEAPIKey}
                      initialValue=""
                      init={{
                        height: 200,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                      }}
                      onEditorChange={(e) => field.onChange(e)}
                      value={field.value}
                    />
                  </div>
                )}
                {["text", "textarea", 'number'].includes(field.type) && (
                  <Input
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder(
                      locale.lotForm.commonInformation.fields[field.name]
                        .placeholder
                    )}
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                    className={`${!field.valid ? "invalid" : ""} ${field.type === 'number' ? styles.numberInput: ''}`}
                  />
                )}
                {["date"].includes(field.type) && (
                  <DateInput
                    date={field.value ? moment(field.value).toDate() : undefined}
                    onChange={(value) => field.onChange(value, lotForm)}
                    format={locale.dateTime.format}
                    maskFormat={locale.dateTime.maskFormat}
				            placeholder={locale.dateTime.format}
                    mask={locale.dateTime.mask}
                    locale={Locales[locale.dateTime.localeFnsName]}
                    minimumDate={
                      field.minDate ? new Date(field.minDate) : undefined
                    }
                    disabled={
                      field.checkForDisable
                        ? field.checkForDisable(field)
                        : false
                    }
                    className={`input ${!field.valid ? "invalid" : null}`}
                    name={field.name}
                  />
                )}
                {field.message && (
                  <p className="fieldMessage" data-type={field.message.type}>
                    {field.message.content}
                  </p>
                )}
              </Col>
            </FormGroup>
          ))}
          <hr />
          <h4>{locale.lotForm.roadmap.header}</h4>
          {lotForm.valid() || lotForm.roadMap.items.length > 0 ? (
            <div>
              {lotForm.roadMap.items.map((item) => (
                <div className={styles.roadmapItem} key={item.id}>
                  <FormGroup row>
                    {item.fields.map((field) => (
                      <Col
                        md={field.width}
                        key={field.id}
                        className={`${
                          field.type === "hidden" ? styles.hidden : ""
                        }`}
                      >
                        {field.type === "hidden" && (
                          <input type="hidden" value={field.value} name={field.name} />
                        )}
                        {field.type !== "hidden" && (
                          <Label for={field.name}>
                            {field.label(
                              locale.lotForm.roadmap.fields[field.name].label
                            )}
                          </Label>
                        )}
                        {field.type === "tinymce" && (
                          <div className={!field.valid ? styles.lotEditorInvalid : "" }>
                            <Editor
                              apiKey={TinyMCEAPIKey}
                              initialValue=""
                              init={{
                                height: 200,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help",
                              }}
                              onEditorChange={(e) => field.onChange(e)}
                              value={field.value}
                            />
                          </div>
                        )}
                        {["text", "textarea"].includes(field.type) && (
                          <Input
                            type={field.type}
                            name={field.name}
                            placeholder={field.placeholder(
                              locale.lotForm.roadmap.fields[field.name]
                                .placeholder
                            )}
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            className={!field.valid ? "invalid" : ""}
                          />
                        )}
                        {["date"].includes(field.type) && (
                          <DateInput
                            date={ field.value ? moment(field.value).toDate() : undefined }
                            onChange={(value) => field.onChange(value, lotForm) }

                            format={locale.dateTime.format}
                            maskFormat={locale.dateTime.maskFormat}
                            placeholder={locale.dateTime.format}
                            mask={locale.dateTime.mask}
                            locale={Locales[locale.dateTime.localeFnsName]}
                            minimumDate={ field.minDate ? new Date(field.minDate) : undefined }
                            maximumDate={ field.maxDate ? new Date(field.maxDate) : undefined }
                            disabled={ field.checkForDisable ? field.checkForDisable(field) : false}
                            className={`input ${!field.valid ? "invalid" : null}`}
                            name={field.name}
                          />
                        )}
                        {field.message && (
                          <p
                            className="fieldMessage"
                            data-type={field.message.type}
                          >
                            {field.message.content}
                          </p>
                        )}
                      </Col>
                    ))}
                  </FormGroup>
                  <Button
                    color="danger"
                    onClick={(e) => lotForm.roadMap.removeItem(e, item)}
                  >
                    {locale.buttons.removeLotStage}
                  </Button>
                  <hr />
                </div>
              ))}
              <div>
                <Button
                  color="primary"
                  onClick={(e) => lotForm.roadMap.createStage(e, lotForm)}
                >
                  {locale.buttons.createLotStage}
                </Button>
                {!lotForm.roadMap.valid && lotForm.roadMap.items.length === 0 && (
                  <p className="error">
                    {locale.lotForm.roadmap.requiredError}
                  </p>
                )}
              </div>
            </div>
          ) : (
            <span>{locale.lotForm.roadmap.fillRequiredFill}</span>
          )}
          <hr />
          <h4>{locale.lotForm.participants.header}</h4>
          {lotForm.participiants.items.map((item) => (
            <div className={`${styles.participiantItem}`} key={item.id}>
              <FormGroup row>
                {item.fields.map((field) => (
                  <Col
                    md={field.width}
                    className={`${
                      field.type === "hidden" ? styles.hidden : ""
                    }`}
                  >
                    {["text", "date", "textarea"].includes(field.type) && (
                      <Input
                        type={field.type}
                        name={field.name}
                        placeholder={field.placeholder(
                          locale.lotForm.participants.fields[field.name]
                            .placeholder
                        )}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        className={!field.valid ? "invalid" : ""}
                      />
                    )}
                    {field.message && (
                      <p
                        className="fieldMessage"
                        data-type={field.message.type}
                      >
                        {field.message.content}
                      </p>
                    )}
                  </Col>
                ))}
              </FormGroup>
              <Button
                color="danger"
                onClick={(e) => lotForm.participiants.removeItem(e, item)}
              >
                {locale.buttons.removeParticipant}
              </Button>
              <hr />
            </div>
          ))}
          <div>
            <Button
              color="primary"
              onClick={(e) => lotForm.participiants.createParticipiant(e)}
            >
              {locale.buttons.createParticipant}
            </Button>
            {!lotForm.participiants.valid && (
                  <p className="error">
                    {locale.lotForm.participants.requiredError}
                  </p>
                )}
          </div>
          <hr />
          <div>
            <h4>{locale.lotForm.documents.header}</h4>
            <ul className={styles.documentList}>
              {lotForm.files.items.map((item) => (
                <li key={item.file.id}>
                  <FaFile />
                  <span>
                    <a
                      href={`${PUBLIC_URL}${item.file.publicUrl}`}
                      target="_blank"
                    >
                      {item.file.originalFilename}
                    </a>
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <FormGroup>
            <input
              type="file"
              name="file"
              ref={uploadFileInput}
              style={{ display: "none" }}
              onChange={(e) => fileUploadChangeHandler(e)}
            ></input>
          </FormGroup>
          <Button color="primary" onClick={(e) => uploadFile()}>
            {locale.buttons.uploadDocument}
          </Button>
          <hr />
          <Button
            color="success"
            size="lg"
            disabled={!lotForm.valid() || !lotForm.roadMap.valid || !lotForm.participiants.valid}
          >
            {locale.buttons.saveChanges}
          </Button>
          {(!lotForm.valid() || !lotForm.roadMap.valid || !lotForm.participiants.valid) ? (
            <p className="error">
              {locale.lotForm.validationMessages.fillAllFields}
            </p>
          ): null}
        </Form>
      );
    }
  )
);

export default LotForm;
