import { ethers } from "ethers";
import { contractAddress } from "../config/config";
// Stores
import UserStore from "../stores/user.store";

class MetaMask {
  constructor() {
    this.ethereum = null;
    this.ethers = null;
  }
  async init() {
    if (this.pluginInstalled()) {
      this.ethereum = window.ethereum;
      this.ethers = new ethers.providers.Web3Provider(window.ethereum);

      const connected = await this.pluginConnected();
      if (!connected) {
        this.connect();
      }
      this.ethereum.on("networkChanged", () => {
        this.connect();
      });
    }
  }
  pluginInstalled() {
    return typeof window.ethereum !== "undefined" && window.ethereum.isMetaMask;
  }
  async pluginConnected() {
    if (!this.pluginInstalled()) return false;
    const accounts = await this.ethers.listAccounts();
    return accounts.length > 0;
  }
  async connect() {
    const accounts = await this.ethereum.request({
      method: "eth_requestAccounts",
    });

    if (accounts && accounts.length) {
      UserStore.updateCurrentUser({
        id: UserStore.currentUser.id,
        ethAddress: accounts[0],
      });
    }

    this.ethereum
      .request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: contractAddress,
            symbol: "WCT",
            decimals: 2,
            image: "https://wct.theinvaders.pro/images/logo.png",
          },
        },
      })
      .then((success) => {
        if (success) {
          console.log("WCT successfully added to wallet!");
        } else {
          throw new Error("Something went wrong.");
        }
      })
      .catch(console.error);
  }
}

export default new MetaMask();
