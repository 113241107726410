import React, {useRef,useMemo, useCallback, useState, useEffect} from "react";
import { PopoverBody, Input } from "reactstrap";
import * as DatePicker  from "react-nice-dates";
import styles from "./container.module.sass";
import moment from 'moment';
import NumberFormat from "react-number-format";
import cn from 'classnames';

const {Popover, DatePickerCalendar}  = (DatePicker as any);

type DateInputProps = {
	name?: string;
	date?: Date;
	value?: string;
	onChange: Function;
	placeholder?: string;
	className?: string;
	format: string;
	mask: Array<string>;
	maskFormat: string;
	minimumDate?: Date;
	maximumDate?: Date;
	disabled?: boolean;
	locale:any;
};

const DateInput: React.FC<DateInputProps> = (props: any) => {
	const {
		name,
		date, onChange, format, maskFormat, placeholder, mask,
		minimumDate, maximumDate, disabled,
		className, locale,
	} = props;

	const [show, setShow] = useState(false);

	const id = useMemo(() => ('_' + Math.random().toString(36).substr(2, 9)), []);
	const onChangeRawCallback = useCallback((value) => {
		onChange && onChange(value);
	}, [onChange]);

	useEffect(() => {
		const callback = (evt) => {
			const isClckInContainer = !!evt.target.closest(`.wct-date-input-open-${id}`);
			if (!isClckInContainer) { setShow(false); }
		}
		if (show) { document.addEventListener('click', callback) }
		return () => document.removeEventListener('click', callback);
	}, [show])

	const val = useMemo(() => {
		if (!date) return "";
		const target = moment(date);
		return target.format(format);
	}, [date])

	return (
		<div className={cn('wct-date-input', {[`wct-date-input-open-${id}`]: show}) }>
			<NumberFormat
				type={"text"}
				name={name}
				onValueChange={(values) => {
					const {formattedValue, value} = values;
					const sourceDate = moment(formattedValue, format, true);

					if (sourceDate.isValid() ) {
						onChangeRawCallback(sourceDate.toDate());
					}
				}}
				value={val}
				format={maskFormat}
				placeholder={format}
				mask={mask}
				allowEmptyFormatting
				onBlur={(evt: any) => {
					const isClckInContainer = !!(evt.relatedTarget || evt.target).closest(`.wct-date-input-open-${id}`);
					if (!isClckInContainer) { setShow(false); }
				}}
				onFocus={() => { setShow(true) }}
				className={`form-control ${className}`}
				disabled={disabled}
			/>
			<Popover open={show} >
				<DatePickerCalendar
					locale={locale}
					date={date}
					minimumDate={minimumDate}
					maximumDate={maximumDate}
					onDateChange={(date) => {
						onChangeRawCallback(moment(date).toDate());
						setShow(false)
					}}
				/>
			</Popover>
		</div>
	);
}
export default DateInput;
