import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Row, Col, Button } from "reactstrap";

// Components
import Block from "../../components/ui/container/container";

// Styles
import styles from "./profile.module.sass";

const ProfilePage: React.FC = inject("UserStore", "LocalesStore")(
  observer(({ UserStore, LocalesStore, Contents }: any) => {
    const { locale } = LocalesStore;
    return (
      <div className={styles.profile}>
        <Block>
          <h3>{ locale.profile.kyc.header }</h3>
          <Row>
            <Col>
              <p>{ locale.profile.kyc.noVerified }</p>
            </Col>
            <Col className="text-right">
              <Button color="success" disabled>
                { locale.buttons.passVerification }
              </Button>
            </Col>
          </Row>
        </Block>
        <Block>
          <h3>{ locale.profile.twoFactorAuthentication.header }</h3>
          <Row>
            <Col>
              <p>{ locale.profile.twoFactorAuthentication.twoFactorAuthenticationDescription }</p>
            </Col>
            <Col className="text-right">
              <Button color="success" disabled>
                { locale.buttons.connectTwoFactorAuthentication }
              </Button>
            </Col>
          </Row>
        </Block>
      </div>
    );
  })
);

export default ProfilePage;
