import React, { useEffect } from "react";
import { Row, Col, Button, Badge, Table } from "reactstrap";
import NumberFormat from "react-number-format";
import moment from "moment";
import { Link } from "react-router-dom";

// Types
import { LotItemType } from "../../types/lots";

// Styles
import styles from "./allLotsItem.module.sass";
import lotsStore from "../../stores/lots.store";

// Components
import PaymentProgress from "../paymentProgress/paymentProgress";

type AllLotsItemComponentProps = {
  lot: LotItemType;
  approve: Function;
  reject: Function;
  closeLot: Function;
  currentUser: any;
  calculateInvestments: Function;
  locale: any;
};

const getBadgeColor = (lot) => {
  switch (lot.status) {
    case "ACTIVE":
      return "success";
    case "REJECTED":
      return "danger";
    default:
      return "primary";
  }
};

const AllLotsItemComponent: React.FC<AllLotsItemComponentProps> = ({
  lot,
  approve,
  reject,
  closeLot,
  currentUser,
  calculateInvestments,
  locale,
}: AllLotsItemComponentProps) => (
  <div className={styles.LotsItem}>
    <Row>
      <Col>
        <h3>
          <Link to={`/lots/${lot.id}`}>{lot.shortName}</Link>
        </h3>
        <PaymentProgress
          remaining={lot.summaryValue - calculateInvestments(lot)}
          value={(calculateInvestments(lot) / lot.summaryValue) * 100}
          lotStatus={lot.status?.name}
          locale={locale}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: lot.shortDescription }}
        ></div>
        <hr />
      </Col>
      <Col>
        <div className={styles.details}>
          <Badge color={getBadgeColor(lot)} pill>
            {locale.statuses[lot.status?.name]}
          </Badge>
          <Table striped bordered size="sm">
            <tbody>
              <tr>
                <td>{locale.lotDetails.totalInvestment}</td>
                <td>
                  <NumberFormat
                    value={lot.summaryValue}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </td>
              </tr>
              <tr>
                <td>{locale.lotDetails.minimumContribution}</td>
                <td>
                  <NumberFormat
                    value={lot.minPaidSumm}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </td>
              </tr>
              <tr>
                <td>{locale.lotDetails.profit}</td>
                <td>{lot.profit}</td>
              </tr>
            </tbody>
          </Table>
          <Table size="sm">
            <tbody>
              {lot.stages.map((stage, index) => (
                <tr key={stage.id}>
                  {!index && (
                    <td
                      rowSpan={lot.stages.length}
                      className={`${styles.stageTableColumn} bolder`}
                    >
                      {locale.lotDetails.stages}
                    </td>
                  )}
                  <td
                    className="bolder"
                    dangerouslySetInnerHTML={{ __html: stage.stageDescription }}
                  ></td>
                  <td>{moment(stage.date).format("DD.MM.YYYY")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Table size="sm" striped condensed>
            <tbody>
              <tr>
                <td>{locale.lotDetails.startOfCollection}</td>
                <td>
                  {moment(lot.startOfCollection).isValid()
                    ? moment(lot.startOfCollection).format("DD.MM.YYYY")
                    : "Не указан"}
                </td>
              </tr>
              <tr>
                <td>{locale.lotDetails.endOfCollection}</td>
                <td>
                  {moment(lot.endOfCollection).isValid()
                    ? moment(lot.endOfCollection).format("DD.MM.YYYY")
                    : "Не указан"}
                </td>
              </tr>
              <tr>
                <td>{locale.lotDetails.startOfExecution}</td>
                <td>
                  {moment(lot.startOfExecution).isValid()
                    ? moment(lot.startOfExecution).format("DD.MM.YYYY")
                    : "Не указан"}
                </td>
              </tr>
              <tr>
                <td>{locale.lotDetails.endOfExecution}</td>
                <td>
                  {moment(lot.endOfExecution).isValid()
                    ? moment(lot.endOfExecution).format("DD.MM.YYYY")
                    : "Не указан"}
                </td>
              </tr>
              <tr>
                <td>{locale.lotDetails.returnInvestmentDate}</td>
                <td>
                  {moment(lot.returnInvestmentsDate).isValid()
                    ? moment(lot.returnInvestmentsDate).format("DD.MM.YYYY")
                    : "Не указан"}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        {currentUser.role.name === "admin" &&
          ["DRAFT", "MODERATION"].includes(lot.status?.name) && (
            <Link to={`/lots/${lot.id}/edit`}>
              <Button color="primary">{locale.buttons.edit}</Button>
            </Link>
          )}
        {lot.status?.name === "EXECUTION" && currentUser.role.name === "admin" && (
          <Button color="primary" onClick={() => closeLot(lot)}>
            {locale.buttons.closeLotDev}
          </Button>
        )}
        {["DRAFT", "MODERATION"].includes(lot.status?.name) &&
          currentUser.role.name === "admin" && (
            <Button color="success" onClick={() => approve(lot)}>
              {locale.buttons.approveLot}
            </Button>
          )}
        {["DRAFT", "MODERATION"].includes(lot.status?.name) &&
          currentUser.role.name === "admin" && (
            <Button color="danger" onClick={() => reject(lot)}>
              {locale.buttons.rejectLot}
            </Button>
          )}
      </Col>
    </Row>
  </div>
);

export default AllLotsItemComponent;
