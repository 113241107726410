import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { observer, inject } from "mobx-react";

const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact?: boolean;
}> = inject("UserStore")(observer(({ UserStore, ...props }: any) => {
  return UserStore.loggedIn ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to="/signup" />
  );
}));
export default PrivateRoute;
