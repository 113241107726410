import { GraphQLAPIURL } from "../config/config";
import axios from "axios";
import escapeQuotes from "../helpers/escapeQuote";
import { AwesomeGraphQLClient } from "awesome-graphql-client";

const client = new AwesomeGraphQLClient({ endpoint: GraphQLAPIURL });

export async function getInvestmentsAction({ lotId = null, userId = null }) {
  const request = `#graphql
    query (
      $condition: InvestmentWhereInput,
    ){
        allInvestments(where: $condition) {
            id,
            value,
            currency {
                shortName,
                fullName,
                symbol
            },
            owner{
                id,
                email
            },
            date,
            lot{
                id,
                shortName,
                fullName,
                summaryValue,
                minPaidSumm,
                startOfCollection,
                endOfCollection,
                investments {
                    id,
                    value,
                    currency {
                        symbol
                    }
                }
            }
      }
    }
    `;

  let condition: any = {};

  if (lotId) condition.lot = { id: lotId };
  if (userId) condition.owner = { id: userId };

  const response: any = await client.request(request, {
    condition,
  });

  return response.allInvestments;
}

export async function createInvestmentAction({
  lotId = null,
  userId = null,
  currencyId = null,
  summ,
}) {
  const request = `#graphql
  mutation (
    $data: InvestmentCreateInput
  ){
    createInvestment(data: $data) {
          id,
          value,
          currency {
              shortName,
              fullName,
              symbol
          },
          owner{
              id,
              email
          },
          date,
          lot{
              id,
              shortName,
              fullName,
              summaryValue,
              minPaidSumm,
              startOfCollection,
              endOfCollection,
              status {
                id,
                name
              },
              investments {
                  id,
                  value,
                  currency {
                      symbol
                  }
              }
          }
    }
  }
  `;

  const response: any = await client.request(request, {
    data: {
      value: +summ,
      currency: {
        connect: {id: currencyId}
      },
      owner: {
        connect: {id: userId}
      },
      date: new Date(),
      lot: {
        connect: {id: lotId}
      },
    },
  });

  return response.createInvestment;
}
