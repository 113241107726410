import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Styles
import styles from "./header.module.sass";

// Assets
import Logo from "../../assets/images/logo.png";

// Types
import { MenuItem } from "../../types/menu";

// Components
import MainMenu from "../ui/mainmenu/mainmenu";
import ProfileSnippetComponent from "./profileSnippet/profileSnippet";
import LocaleSnippet from "./localeSnippet/localeSnippet";
import { FaBars } from "react-icons/fa";

interface HeaderComponentProps {
  menuItems: MenuItem[];
  currentUser: any;
  loggedIn: boolean;
  setActiveItem: any;
  pathname: any;
  logout: any;
  locale: any;
}

const HeaderComponent: React.SFC<HeaderComponentProps> = ({
  menuItems,
  setActiveItem,
  currentUser,
  loggedIn,
  pathname,
  logout,
  locale,
}: HeaderComponentProps) => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  return (
    <header className={styles.header}>
      <div className={styles.desktop}>
        <div className={styles.logoContainer}>

          <Link to={loggedIn ? "/lots" : '/signup' } className={styles.logo}>
            <img src={Logo} />
          </Link>
          <LocaleSnippet />
        </div>

        {loggedIn && (
          <MainMenu
            pathname={pathname}
            menuItems={menuItems}
            setActiveItem={setActiveItem}
            currentUser={currentUser}
            locale={locale}
          />
        )}
        <ProfileSnippetComponent
          user={currentUser}
          loggedIn={loggedIn}
          logout={logout}
          locale={locale}
        />
      </div>
      <div className={styles.mobile}>
        <div className={styles.mobileMenu}>
          <div
            className={styles.menuIcon}
            onClick={() => setMobileMenuActive(!mobileMenuActive)}
          >
            <FaBars />
          </div>
          <div
            className={`${styles.dropdown} ${
              mobileMenuActive ? styles.dropdownActive : null
            }`}
          >
            <div className={styles.localeSnippet}>
              <LocaleSnippet />
            </div>
            {loggedIn && (
              <MainMenu
                pathname={pathname}
                menuItems={menuItems}
                setActiveItem={setActiveItem}
                currentUser={currentUser}
                locale={locale}
                setMobileMenuActive={setMobileMenuActive}
              />
            )}
          </div>
        </div>
        <Link to="/" className={styles.logo}>
          <img src={Logo} />
        </Link>
        <ProfileSnippetComponent
          user={currentUser}
          loggedIn={loggedIn}
          logout={logout}
          locale={locale}
        />
      </div>
    </header>
  );
};

export default HeaderComponent;
