import { observable, action } from "mobx";
import { v4 as uuidv4 } from "uuid";

import LocalesStore from "../stores/locales.store";

// Stores
import InvestmentStore from "./investment.store";
import OrdersStore from "./order.store";
import CurrenciesStore from "./currencies.store";
import UserStore from './user.store';

class ModalsStore {
  // Properties
  @observable modals: any[] = [];
  @observable investModal = {
    name: "investModal",
    title: "Инвестировать",
    summ: null,
    isValid: false,
    validationMessage: '',
    visible: false,
    isSumOverflow: false,
    change: function (value, validSum) {
      this.summ = value;
      if (value  === '') {
        this.validationMessage = '';
        this.isValid = false;
        return;
      }

      const valueFloat = parseFloat(value);
      if (valueFloat === 0) {
        this.validationMessage = `${LocalesStore.locale.investments.modals.invest.invalidInvestZero}`;
        this.isValid = false;
        return;
      }

      if (valueFloat < validSum) {
        this.validationMessage = `${LocalesStore.locale.investments.modals.invest.invalidInvestSum} ${validSum}`;
        this.isValid = false;
        return;
      }

      const usdBalance = UserStore!.currentUser!.balances!.find(balance => balance.currency.shortName === 'USD');
      if (valueFloat > usdBalance.value) {
        this.validationMessage = `${LocalesStore.locale.investments.modals.invest.invalidInsufficientFunds}`;
        this.isValid = false;
        return;
      }
      this.isValid = true;
      this.validationMessage = '';
    },
    show: function () {
      this.visible = true;
    },
    hide: function () {
      this.summ = null;
      this.visible = false;
    },
    switch: function () {
      this.visible = !this.visible;
    },
    submit: function ({ userId, lotId }) {
      this.isSumOverflow = false;
      InvestmentStore.createInvestment({ userId, lotId, summ: this.summ });
      this.visible = false;
      this.summ = null;
    },
  };
  @observable topUpModal = {
    name: "topUpModal",
    title: "Top up",
    summ: null,
    currency: CurrenciesStore.items[0] ? CurrenciesStore.items[0].id : null,
    visible: false,
    change: function (value, fieldName) {
      console.log(fieldName);
      this[fieldName] = value;
    },
    show: function () {
      this.visible = true;
    },
    hide: function () {
      this.summ = null;
      this.visible = false;
    },
    switch: function () {
      this.visible = !this.visible;
    },
    submit: function ({ userId }) {
      const newOrder = {
        value: this.summ,
        currency: this.currency,
        status: "ACTIVE",
        type: "topup",
        owner: userId,
      };
      OrdersStore.createOrder(newOrder);
      this.visible = false;
      this.summ = null;
    },
  };
  @observable withdrawModal = {
    name: "withdrawModal",
    title: "Withdraw",
    summ: null,
    currency: CurrenciesStore.items[0] ? CurrenciesStore.items[0].id : null,
    requisites: "",
    visible: false,
    change: function (value, fieldName) {
      this[fieldName] = value;
    },
    show: function () {
      this.visible = true;
    },
    hide: function () {
      this.summ = null;
      this.visible = false;
    },
    switch: function () {
      this.visible = !this.visible;
    },
    submit: function ({ userId }) {
      const newOrder = {
        value: this.summ,
        requisites: this.requisites,
        currency: this.currency,
        status: "CONFIRMATION",
        type: "withdraw",
        owner: userId,
      };
      OrdersStore.createOrder(newOrder);
      this.visible = false;
      this.summ = null;
    },
  };
}

export default new ModalsStore();
