import { observable, computed, action } from "mobx";
import ru_RU from "../locales/ru_RU.json";
import en_US from "../locales/en_US.json";
import ge_GE from "../locales/ge_GE.json";

// Icons
import ru from "../assets/images/ru.svg";
import en from "../assets/images/en.svg";
import ge from "../assets/images/ge.svg";

class LocalesStore {
  // Properties
  @observable currentLocale: any = {
    id: 1,
    name: "en_US",
    icon: en,
    data: en_US,
  };
  @observable locales = {
    ru_RU: {
      id: 0,
      name: "ru_RU",
      icon: ru,
      data: ru_RU,
    },
    en_US: {
      id: 1,
      name: "en_US",
      icon: en,
      data: en_US,
    },
    ge_GE: {
      id: 2,
      name: "ge_GE",
      icon: ge,
      data: ge_GE,
    },
  };

  @computed get locale() {
    return this.currentLocale.data;
  }

  @action getLocale() {
    const userLocale = window.localStorage.getItem("wctUserLocale");
    if (userLocale) {
      this.currentLocale = { ...this.locales[userLocale] };
    } else {
      this.currentLocale = { ...this.locales["en_US"] };
    }
  }

  @action switchLocale() {
    const localesArray = Object.entries(this.locales);
    const currentIndex = this.currentLocale.id;

    if (currentIndex >= localesArray.length - 1) {
      this.currentLocale = { ...localesArray[0][1] };
    } else {
      this.currentLocale = { ...localesArray[currentIndex + 1][1] };
    }
    window.localStorage.setItem("wctUserLocale", this.currentLocale.name);
  }
}

export default new LocalesStore();
