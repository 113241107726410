import React, { useEffect, useRef } from "react";
import { observer, inject } from "mobx-react";
import { Link } from "react-router-dom";
import {
  Card,
  CardTitle,
  CardBody,
  CardText,
  Table,
  Row,
  Col,
} from "reactstrap";
import moment from "moment";
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
// Components
import Block from "../../components/ui/container/container";
import { ResponsivePie } from "@nivo/pie";
import Progress from "../../components/paymentProgress/paymentProgress";

import LotCardItem from './components/LotCardItem'

// Styles
import styles from "./investments.module.sass";

const theme = {
  fontSize: 16,
};

type groupedInvestment = {
  items: any[];
  summary: number;
};

const InvestmentsPage: React.FC = inject(
  "InvestmentStore",
  "LotsStore",
  "UserStore",
  "LocalesStore"
)(
  observer(
    ({
      InvestmentStore,
      UserStore,
      LotsStore,
      LocalesStore,
      Contents,
    }: any) => {
      useEffect(() => {
        const userId = UserStore.currentUser.id;
        InvestmentStore.getInvestments({ userId });
        LotsStore.getAllLots();
      }, []);

      const { locale } = LocalesStore;
      const groupedInvestments = Object.entries(
        InvestmentStore.groupedInvestments
      ).map(([lotId, investments]) => investments as groupedInvestment);

      const pieData = groupedInvestments.map((investment) => ({
        id: investment.items[0].lot.shortName,
        label: investment.items[0].lot.shortName,
        value: investment.summary,
        fill: "#333",
      }));
      const lastLots = LotsStore.items;
      return (
        <div>
          <div className={styles.investmentsWrapper}>
            <h3>{locale.investments.active.header}</h3>
            <Block>
              <div className={styles.pieContainer}>
                {!groupedInvestments.length ? (
                  <p>{locale.validatorMessages.noInvestments}</p>
                ) : (
                  <ResponsivePie
                    data={pieData}
                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                    innerRadius={0.5}
                    padAngle={2}
                    cornerRadius={3}
                    colors={{ scheme: "category10" }}
                    borderWidth={0}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    radialLabel="label"
                    radialLabelsSkipAngle={10}
                    radialLabelsTextColor="#333333"
                    radialLabelsLinkColor={{ from: "color" }}
                    sliceLabelsSkipAngle={10}
                    sliceLabelsTextColor="#fff"
                    radialLabelsLinkStrokeWidth={3}
                    theme={theme}
                  />
                )}
              </div>
            </Block>
            <h3>{locale.investments.lastLots.header}</h3>
            <div className={styles.investmentsLotSlider} >
            {(lastLots.length !== 0)
                ?(<div className={styles.scrollMenuContainer}><ScrollMenu
                    data={lastLots.map(item => (<LotCardItem key={item.id} item={item} locale={locale} styles={styles} />))}
                    arrowLeft={<div className={styles.scrollArrow}> <FaArrowAltCircleLeft size={30}/> </div> }
                    arrowRight={<div className={styles.scrollArrow}> <FaArrowAltCircleRight size={30}/> </div>}
                    hideArrows={true}
                    arrowDisabledClass={styles.scrollArrowDisabledClass}
                    itemClass={`menu-item-wrapper ${styles.scrollItemWrapper}`}
                    itemClassActive={styles.scrollItemActive}
                    alignCenter={false}
                  /></div>)

                : <p>{locale.validatorMessages.noLots}</p>
              }
            </div>
            <h3>{locale.investments.myInvestments.header}</h3>

            {groupedInvestments.map(({ items, summary }) => (
              <Block key={items[0].id}>
                <div className="mobileOverflowX">
                <div className={styles.myInvestmentsItem}>
                  <Row>
                    <Col>
                      <Link to={`/lots/${items[0].lot.id}`}>
                        <h4>{items[0].lot.shortName}</h4>
                      </Link>
                    </Col>
                  </Row>
                  <div className={styles.myInvestmentsItemDetails}>
                    <Progress
                      remaining={
                        items[0].lot.summaryValue -
                        LotsStore.calculateInvestments(items[0].lot)
                      }
                      value={
                        (LotsStore.calculateInvestments(items[0].lot) /
                          items[0].lot.summaryValue) *
                        100
                      }
                      lotStatus={items[0].lot.status}
                      locale={locale}
                    />
                    <Table condensed>
                      <thead>
                        <tr>
                          <th>{locale.lotDetails.endOfCollection}</th>
                          <th>{locale.lotDetails.startOfExecution}</th>
                          <th>{locale.lotDetails.collected}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {moment(items[0].lot.endOfCollection).format(
                              "DD.MM.YYYY"
                            )}
                          </td>
                          <td>
                            {moment(items[0].lot.startOfCollection).format(
                              "DD.MM.YYYY"
                            )}
                          </td>
                          <td>
                            {LotsStore.calculateInvestments(items[0].lot)} $
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
                </div>
              </Block>
            ))}
            {!groupedInvestments.length && (
              <p>{locale.validatorMessages.noInvestments}</p>
            )}
          </div>
        </div>
      );
    }
  )
);

export default InvestmentsPage;
