import React, { useState } from "react";
import { observer, inject } from "mobx-react";

// Styles
import styles from "./localeSnippet.module.sass";

const LocaleSnippet = inject("LocalesStore")(
  observer(({ LocalesStore }: any) => {
    return (
      <div className={styles.localeSnippet}>
        <img
          src={LocalesStore.currentLocale.icon}
          className={styles.icon}
          onClick={LocalesStore.switchLocale.bind(LocalesStore)}
        />
      </div>
    );
  })
);

export default LocaleSnippet;
