import React, { useEffect } from "react";

// Styles
import styles from './container.module.sass';

const ContainerComponent: React.FC = ({ children }: any) => (
  <div className={styles.container}>
    { children }
  </div>
);

export default ContainerComponent;
