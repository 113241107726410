import React, { useEffect } from "react";
import { inject, observer } from 'mobx-react';

// Components
import { Alert } from "reactstrap";

// Styles
import styles from "./flashes.module.sass";

const FlashesComponent: React.FC<any> = inject("UIStore")(observer(({ UIStore }: any) => (
    <div className={styles.flashes}>
      {UIStore.flashes.map((flash) => (
        <Alert color={flash.type} key={flash.id} className={styles.flash}>{flash.content}</Alert>
      ))}
    </div>
  )));

export default FlashesComponent;
