import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import moment from "moment";

// Components
import { Button, Table } from "reactstrap";
import Block from "../../../components/ui/container/container";
// Styles
import styles from "./orderDetails.module.sass";

const OrderDetails: React.FC = inject(
  "routing",
  "UserStore",
  "OrdersStore",
  "UIStore",
  "LocalesStore"
)(
  observer(({ OrdersStore, UIStore, LocalesStore }: any) => {
    // @ts-ignore
    const { orderId } = useParams();
    useEffect(() => {
      OrdersStore.getOrder({ id: orderId });
    }, []);
    const order = OrdersStore.item;
    const { locale } = LocalesStore;
    const paidHandler = async (order) => {
      await OrdersStore.updateOrder({
        ...order,
        status: "CONFIRMATION",
      });
      UIStore.createFlash({
        type: "success",
        content: locale.flashMessages.orderUpdated,
      });
    };
    return (
      order && (
        <Block>
          <h1>
            {locale.orderDetails.header} #{order.id}
          </h1>
          <hr />
          <p>
            <strong>{locale.orderDetails.initiator}:</strong>{" "}
            {order.owner.email}
            <br />
            <strong>{locale.orderDetails.type}:</strong>{" "}
            <span>{locale.orderTypes[order.type]}</span>
            <br />
            <strong>{locale.orderDetails.status}:</strong>{" "}
            {locale.orderStatuses[order.status]}
            <br />
            <strong>{locale.orderDetails.summ}:</strong> {order.value}{" "}
            {order.currency.shortName}
          </p>
          <hr />
          {order.type === "topup" && (
            <div>
              <h3>{locale.orderDetails.paymentDetails}</h3>
              <p>{locale.orderDetails.paymentDescription}</p>
              <Table striped condensed>
                <tbody>
                  <tr>
                    <td>Intermediary Bank</td>
                    <td>
                      CITIUS33
                      <br />
                      Citibank N.A., New York, NY
                    </td>
                  </tr>
                  <tr>
                    <td>Benefeciary's Bank</td>
                    <td>CBGURUMM</td>
                  </tr>
                  <tr>
                    <td>Benefeciary's Account</td>
                    <td>
                      New Zeland
                      <br />
                      LV90CBBR1122105200010
                    </td>
                  </tr>
                  <tr>
                    <td>SWIFT</td>
                    <td>CBBRLV22</td>
                  </tr>
                  <tr>
                    <td>Bank Address</td>
                    <td>Maza Pils iela 13, Riga, LV-1050, Latvia</td>
                  </tr>
                  <tr>
                    <td>Details of payment</td>
                    <td>Invoice 03092013/DP002/D04290</td>
                  </tr>
                </tbody>
              </Table>
              <p>{locale.orderDetails.speedUp}</p>
              <hr />
              <div className="text-center">
                <Button
                  color="success"
                  size="lg"
                  onClick={() => paidHandler(order)}
                  disabled={order.status !== "ACTIVE"}
                >
                  {locale.buttons.paid}
                </Button>
              </div>
            </div>
          )}
          {order.type === "withdraw" && (
            <div>
              <h3>{locale.orderDetails.withdrawHeader}</h3>
              <h5>{locale.orderDetails.withdrawBankDetails}</h5>
              <pre className={styles.requisitesText}>{order.requisites}</pre>
            </div>
          )}
          {order.type === "stageFunding" && (
            <div>
              <h3>{ locale.orderDetails.lot }</h3>
              <Link to={`/lots/${order.stage.lot.id}`}>{ order.stage.lot.shortName }</Link>
              <hr />
              <h3>{ locale.orderDetails.stageDescription }</h3>
              <span>
                <strong>{locale.lotForm.roadmap.fields.title.label}:</strong> { order.stage.title }<br />
                <strong>{locale.lotForm.roadmap.fields.stageDescription.label}:</strong> <span dangerouslySetInnerHTML={{ __html: order.stage.stageDescription }} /><br />
                <strong>{locale.lotForm.roadmap.fields.startDate.label}:</strong> { moment(order.stage.startDate).format('DD.MM.YYYY') }<br />
                <strong>{locale.lotForm.roadmap.fields.cost.label}:</strong> { order.stage.cost } $<br />
              </span>
            </div>
          )}
        </Block>
      )
    );
  })
);

export default OrderDetails;
