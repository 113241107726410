import { observable, computed, action } from "mobx";

// API
import { getTransactionsAction } from "../api/transaction";

type Transaction = {
  id: string;
  value: number;
  txId: string;
  from?: string;
  to?: string;
  owner: string;
};

class TransactionsStore {
  // Properties
  @observable items: Transaction[] = [];

  // Computed

  @action async getTransactions({ userId }): Promise<void> {
    try {
      const transactions = await getTransactionsAction({ userId });
      if (transactions) this.items = transactions;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new TransactionsStore();
