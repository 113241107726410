import { observable, computed, action } from "mobx";

// Bad Code need refactoring
export const defaultNavigationPaths = {
  customer: '/investments',
  operator: '/lots',
  admin: '/lots',
  tradinghouse: '/lots',
}

class NavigationStore {
  // Properties
  @observable menuItems = [
    {
      id: 1,
      text: (value: string): string => value,
      name: "allLots",
      path: "/lots",
      active: true,
      roles: ["customer", "operator", "admin", "tradinghouse"],
    },
    {
      id: 2,
      text: (value: string): string => value,
      name: "investments",
      path: "/investments",
      active: false,
      roles: ["customer"],
    },
    {
      id: 3,
      text: (value: string): string => value,
      name: "wallet",
      path: "/wallet",
      active: false,
      roles: ["customer", "tradinghouse"],
    },
    {
      id: 4,
      text: (value: string): string => value,
      name: "profile",
      path: "/profile",
      active: false,
      roles: ["customer", "operator", "admin", "tradinghouse"],
    },
    {
      id: 5,
      text: (value: string): string => value,
      name: "orders",
      path: "/orders",
      active: false,
      roles: ["operator", "admin"],
    },
  ];

  // Computed

  // Actions
  @action setActiveItem(path) {
    this.menuItems = this.menuItems.map((item) => {
      if (path.indexOf(item.path) !== -1) {
        return {
          ...item,
          active: true,
        };
      } else {
        return {
          ...item,
          active: false,
        };
      }
    });
  }
}

export default new NavigationStore();
