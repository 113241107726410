import { GraphQLAPIURL } from "../config/config";
import axios from "axios";
import escapeQuotes from "../helpers/escapeQuote";

export async function getAllCurrenciesAction() {
  const query = `#graphql
  query {
    allCurrencies {
      id,
      shortName,
      fullName,
      symbol
    }
  }
  `;
  const response = await axios
    .post(GraphQLAPIURL, {
      query,
    })
    .then((response) => response.data);
  if (response.errors) return response;
  return response.data.allCurrencies;
}
