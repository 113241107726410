import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Card, CardTitle, CardBody, CardText } from "reactstrap";

// Styles
import styles from "./mainpage.module.sass";

const MainPage: React.FC = inject()(observer(({ Contents }: any) => {
  useEffect(() => {
    // Contents.getContents();
  }, [])
  
  return (
    <div className={styles.mainpage}>
    </div>
  )
}))

export default MainPage;
