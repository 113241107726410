import { observable, computed, action } from "mobx";
import { history } from "../index";

// Stores
import UIStore from "./ui.store";

// API
import {
  getAllOrdersAction,
  createOrderAction,
  getOrderAction,
  updateOrderAction,
} from "../api/order";

type Order = {
  id: string;
  value: string;
  type: string;
  currency: string;
  owner: any;
};

class OrdersStore {
  // Properties
  @observable items: Order[] = [];
  @observable item: Order | null = null;
  // Computed

  @action async getAllOrders(): Promise<Order | undefined> {
    const orders = await getAllOrdersAction();
    console.log(orders);
    this.items = orders;
    return orders;
  }

  @action async getOrder(params): Promise<Order | undefined> {
    const order = await getOrderAction(params);
    this.item = order;
    return order;
  }

  @action async createOrder(order): Promise<Order> {
    const newOrder = await createOrderAction(order);
    this.items.push(newOrder);

    if (newOrder) history.push(`/orders/${newOrder.id}`);
    return newOrder;
  }

  @action async updateOrder(order): Promise<Order> {
    const updatedOrder = await updateOrderAction(order);
    this.items = this.items.map((item) => {
      if (item.id === updatedOrder.id) return updatedOrder;
      return item;
    });
    this.item = order;
    return order;
  }
}

export default new OrdersStore();
